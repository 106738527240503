import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { CourseSubject, useGetCourseThemeQuery } from '@generated/graphql';
import { CourseSubjectColor } from 'types/common';
import BackgroundLayout from './BackgroundLayout';

type CourseLayoutParams = {
  courseId: string;
  blockId?: string;
  lessonId?: string;
  exerciseId?: string;
  elementId?: string;
  subject?: CourseSubject;
};

const CourseLayout = () => {
  const [subject, setSubject] = useState<CourseSubjectColor>('defaultColor');
  const params = useParams<CourseLayoutParams>();
  const { blockId, subject: subjectParam } = params;
  const [searchParams] = useSearchParams();
  const courseId = params.courseId || searchParams.get('courseId');

  const { data, loading } = useGetCourseThemeQuery({
    variables: {
      courseId: courseId!,
    },
    skip: !courseId,
  });

  const courseSubject = data?.courseCard.course.subject || subjectParam;
  useEffect(() => {
    if (courseSubject) setSubject(courseSubject);
  }, [courseSubject]);

  return (
    <BackgroundLayout subject={courseSubject} backgroundType={blockId ? 'background' : 'mainBackground'}>
      <Outlet context={{ subject, setSubject, themeLoading: loading }} />
    </BackgroundLayout>
  );
};

export default CourseLayout;
