import { CourseSubject } from '@generated/graphql';
import ApprovePurple from 'assets/images/common/approve_purple.png';
import SandClocksPurple from 'assets/images/common/sand_clocks_purple_tilted.png';
import ApproveGreen from 'assets/images/common/approve_green.png';
import SandClocksGreen from 'assets/images/common/sand_clocks_green_tilted.png';
import GoalGreen from 'assets/images/common/goal_green.png';
import GoalPurple from 'assets/images/common/goal_purple.png';

type IImages = {
  [key in CourseSubject]: {
    approve: any;
    sandClocks: any;
    goal: any;
  };
};

const IMAGES: IImages = {
  [CourseSubject.Chemistry]: {
    approve: ApprovePurple,
    sandClocks: SandClocksPurple,
    goal: GoalPurple,
  },
  [CourseSubject.Biology]: {
    approve: ApproveGreen,
    sandClocks: SandClocksGreen,
    goal: GoalGreen,
  },
  [CourseSubject.Math]: {
    approve: ApproveGreen,
    sandClocks: SandClocksGreen,
    goal: GoalGreen,
  },
  [CourseSubject.Physics]: {
    approve: ApproveGreen,
    sandClocks: SandClocksGreen,
    goal: GoalGreen,
  },
  [CourseSubject.Literature]: {
    approve: ApproveGreen,
    sandClocks: SandClocksGreen,
    goal: GoalGreen,
  },
  [CourseSubject.SocialScience]: {
    approve: ApproveGreen,
    sandClocks: SandClocksGreen,
    goal: GoalGreen,
  },
  [CourseSubject.Informatics]: {
    approve: ApproveGreen,
    sandClocks: SandClocksGreen,
    goal: GoalGreen,
  },
  [CourseSubject.RussianLanguage]: {
    approve: ApproveGreen,
    sandClocks: SandClocksGreen,
    goal: GoalGreen,
  },
  [CourseSubject.History]: {
    approve: ApproveGreen,
    sandClocks: SandClocksGreen,
    goal: GoalGreen,
  },
};

export default IMAGES;
