import { Box, Theme } from '@mui/material';
import CheckIcon from 'assets/icons/CheckIcon';
import React from 'react';
import useThemeMode from 'hooks/useThemeMode';

interface CourseCardCheckboxProps {
  isSelected: boolean;
}

const CourseCardCheckbox = ({ isSelected }: CourseCardCheckboxProps) => {
  const { isLightTheme } = useThemeMode();

  const getCheckboxBorderColor = (theme: Theme) => {
    if (isSelected) {
      return theme.palette.base[100];
    }
    if (isLightTheme) return theme.palette.base[300];
    return theme.palette.base[500];
  };
  return (
    <Box
      component='div'
      sx={(theme) => ({
        width: 32,
        height: 32,
        border: '2px solid',
        borderColor: getCheckboxBorderColor(theme),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      })}
    >
      {isSelected && <CheckIcon sx={{ color: 'base.100' }} />}
    </Box>
  );
};

export default CourseCardCheckbox;
