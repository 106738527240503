/*
eslint-disable react/no-unknown-property
*/

import * as THREE from 'three';
import { extend, Canvas, RenderProps } from '@react-three/fiber';
import React, { FC, Suspense } from 'react';
import { Box, useTheme } from '@mui/material';
import { CAPYBARA_MODEL, CapybaraType } from 'constants/capybara';
import { Vector3 } from '@react-three/fiber/dist/declarations/src/three-types';

extend(THREE);
const Scene: FC<{
  capybaraType: CapybaraType;
  withoutFloor?: boolean;
  camera?: RenderProps<any>['camera'];
  capybaraPosition?: Vector3;
  floorPosition?: Vector3;
}> = ({ capybaraType, withoutFloor, camera, capybaraPosition, floorPosition }) => {
  const CapybaraModel = CAPYBARA_MODEL[capybaraType] || Box;
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const [x, y, z] = floorPosition as [number, number, number];
  const shadowPosition = [x + 2.5, y + 0.1, z + 0.6] as Vector3;

  return (
    <Suspense fallback={null}>
      <Canvas shadows camera={camera} flat>
        <mesh>
          <ambientLight intensity={1.5} />
          <directionalLight position={[-2, 3, -3]} intensity={1} castShadow />
          <directionalLight position={[0, 4.5, -3]} intensity={1} />
          <directionalLight position={[-2, 5, 3]} intensity={1} />
          <group position={capybaraPosition}>
            <CapybaraModel />
          </group>
        </mesh>
        <group receiveShadow>
          {!withoutFloor && (
            <mesh name='floor' position={floorPosition} rotation={[0.01, 0, 0]}>
              <boxGeometry args={[8, 0, 20]} />
              <meshBasicMaterial color={isLight ? theme.palette.base[100] : theme.palette.base[700]} />
            </mesh>
          )}
          <mesh name='shadow' receiveShadow position={shadowPosition}>
            <boxGeometry attach='geometry' args={[20, 0, 20]} />
            <shadowMaterial attach='material' color={isLight ? theme.palette.base[400] : theme.palette.base[900]} />
          </mesh>
        </group>
      </Canvas>
    </Suspense>
  );
};

export default Scene;

Scene.defaultProps = {
  withoutFloor: false,
  camera: { position: [-8, 0, -0.5], fov: 45 },
  capybaraPosition: [0, -2.2, -0.5],
  floorPosition: [-0.5, -2.3, -0.6],
};
