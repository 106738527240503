import Slider from 'components/Slider';
import BlockCard from 'components/dashboard/BlockCard';
import { CourseQuery } from '@generated/graphql';

const Blocks = ({ blocks }: { blocks: CourseQuery['course']['blocks'] }) => (
  <Slider
    itemWidth={190}
    containerSx={{
      '& > div:first-of-type': {
        px: 2,
        mt: 0.5,
      },
    }}
    header={`Что${'\u00A0'}изучим сегодня?`}
    headerProps={{ variant: 'h1', sx: { whiteSpace: 'pre-wrap' } }}
    swipe
  >
    {blocks?.map((blockItem) => (
      <BlockCard
        isAvailable={blockItem.isAvailable}
        key={`block-${blockItem.id}`}
        totalTaskCount={blockItem.statistic?.lessonsCount.totalCount}
        doneTaskCount={blockItem.statistic?.lessonsCount.completeCount}
        title={blockItem.title}
        backgroundImage={blockItem.coverFile?.url}
        progress={blockItem.statistic?.progress || 0}
        id={blockItem.id}
        unavailableReason={blockItem.unavailableReason}
        availabilityType={blockItem.availabilityType}
      />
    ))}
  </Slider>
);

export default Blocks;
