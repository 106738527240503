import { useController, UseFormReturn } from 'react-hook-form';
import { FormControl, FormControlLabel, RadioProps, RadioGroup, Radio, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { CourseSubjectColor } from 'types/common';
import generateList from 'components/form/generateList';
import { CourseSubject } from '@generated/graphql';
import useThemeMode from 'hooks/useThemeMode';

export interface IRadioButtonProps {
  value: any;
  label: string;
}

type Item = IRadioButtonProps | string | { id: string; content: string };

type IRadioButtonControlProps = {
  subject?: CourseSubjectColor;
  name: string;
  itemList: Item[];
  disabled?: boolean;
  form: UseFormReturn;
};

const RadioButtonControl: FC<RadioProps & IRadioButtonControlProps> = ({ name, subject, itemList, disabled, form }) => {
  const { getValues } = form;
  const {
    field: { onChange, value },
  } = useController({
    defaultValue: '',
    name: `${name}.checked`,
  });

  const { palette } = useTheme();
  const { mode } = useThemeMode();

  const renderRadioButton = (item: Item, index: number) => {
    let label;
    let itemValue;
    if (typeof item === 'string') {
      ({ label, value: itemValue } = generateList(item, index));
    } else if ('content' in item) {
      label = item.content;
      itemValue = item.id;
    } else {
      label = item.label;
      itemValue = item.value;
    }

    const isSelectedValue = value === itemValue;
    const color = isSelectedValue && getValues(name).isCorrect ? palette[CourseSubject.Biology].main : palette.assessment[mode].fault;

    return (
      <FormControlLabel
        key={`${name}.${itemValue}`}
        value={itemValue}
        control={<Radio subject={subject} />}
        label={<Typography dangerouslySetInnerHTML={{ __html: `${label}` }} />}
        disabled={disabled}
        sx={{
          '& p': {
            color: isSelectedValue && typeof value.isCorrect !== 'undefined' ? color : undefined,
          },
          '& .MuiRadio-root.Mui-checked': {
            color: isSelectedValue && typeof value.isCorrect !== 'undefined' ? color : undefined,
          },
        }}
      />
    );
  };

  return (
    <FormControl>
      <RadioGroup name={name} value={value} onChange={onChange}>
        {itemList?.map((item, index) => renderRadioButton(item, index))}
      </RadioGroup>
    </FormControl>
  );
};

RadioButtonControl.defaultProps = {
  subject: undefined,
  disabled: false,
};

export default RadioButtonControl;
