import { DatePicker } from '@mui/x-date-pickers';
import { RegisterOptions, useController } from 'react-hook-form';
import CalendarIcon from 'assets/icons/CalendarIcon';

type DatePickerProps = {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  defaultValue?: string;
  disabled?: boolean;
};

const DatePickerControl = ({ name, label, rules, defaultValue, disabled = false }: DatePickerProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, defaultValue });
  return (
    <DatePicker
      value={field.value || null}
      onChange={field.onChange}
      views={['day', 'month', 'year']}
      format='dd.MM.yyyy'
      label={label}
      disabled={disabled}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      slotProps={{
        field: {
          clearable: true,
        },
        textField: {
          size: 'small',
          fullWidth: true,
          error: !!error,
        },
        clearButton: {
          sx: {
            my: -1,
          },
        },
        openPickerIcon: {
          sx: {
            marginRight: 0,
            height: 23,
          },
        },
        openPickerButton: {
          sx: {
            marginRight: 0,
            my: -1,
          },
        },
      }}
    />
  );
};

export default DatePickerControl;
