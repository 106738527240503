import { Outlet } from 'react-router-dom';
import { CourseSubject } from '@generated/graphql';
import { Dispatch, SetStateAction, useState } from 'react';
import BackgroundLayout from '../../layouts/BackgroundLayout';

type SelectedCoursesByGrade = Record<string, string[]>;

export interface OnboardingOutletProps {
  subject: CourseSubject;
  selectedCoursesByGrade: SelectedCoursesByGrade;
  setSelectedCoursesByGrade: Dispatch<SetStateAction<SelectedCoursesByGrade>>;
}

const OnboardingOutlet = () => {
  const [selectedCoursesByGrade, setSelectedCoursesByGrade] = useState<SelectedCoursesByGrade>({});

  return (
    <BackgroundLayout subject='defaultColor' backgroundType='background'>
      <Outlet context={{ subject: 'defaultColor', selectedCoursesByGrade, setSelectedCoursesByGrade }} />
    </BackgroundLayout>
  );
};

export default OnboardingOutlet;
