import { Card, Stack, Typography } from '@mui/material';
import PromoSubjectCard from 'components/dashboard/PromoSubjectCard';
import { FC } from 'react';
import { CourseSubject } from '@generated/graphql';
import { BackButton } from 'components/index';
import { useNavigate } from 'react-router-dom';
import Text from 'components/ElementsGenerator/elements/Text';

export interface PaymentCardCourse {
  subject: CourseSubject;
  title: string;
}

const PaymentCard: FC<{
  course: PaymentCardCourse;
  coverFile:
    | {
        url: string | undefined;
      }
    | undefined
    | null;
  description: string | undefined;
  cardDescription?: string;
}> = ({ course, coverFile, description, cardDescription }) => {
  const navigate = useNavigate();
  return (
    <Card>
      <Stack spacing={2}>
        <BackButton navigate={() => navigate(-1)} text='Назад' />
        <Typography variant='h2'>Ваш заказ:</Typography>
        <PromoSubjectCard
          containerSx={{ height: 124 }}
          backgroundImage={coverFile?.url}
          subject={course?.subject}
          title={course?.title}
          description={cardDescription}
        />
        <Text content={description!} sx={{ '& ul': { paddingLeft: 2 } }} />
      </Stack>
    </Card>
  );
};

export default PaymentCard;

PaymentCard.defaultProps = {
  cardDescription: undefined,
};
