export default {
  mode: 'light',
  baseShift: {
    text: '#01163F',
    100: '#283750',
    200: '#ACB0B4',
    300: '#B9CCD6',
    400: '#E8ECEF',
    500: '#FFFFFF',
  },
  background: {
    default: '#F8FAFD',
  },
};
