import { Stack } from '@mui/material';
import { FC, useContext } from 'react';
import { ElementFileType, useSendFileInChatBotMutation } from '@generated/graphql';
import PromoCard from 'components/dashboard/PromoCard';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { AuthContext } from 'context/authContext';

interface IFile {
  __typename?: 'ElementFileLkDto' | undefined;
  id: string;
  type: ElementFileType;
  file: { __typename?: 'FileLkDto' | undefined; name: string; url: string };
}

export type DownloadFilesProps = {
  files?: IFile[] | null;
};

export interface IDownloadFiles {
  downloadFile: IFile;
  coverFile: IFile;
}

const DownloadFiles: FC<DownloadFilesProps> = ({ files }) => {
  const { addToast } = useContext(ToastContext);
  const { me } = useContext(AuthContext);
  const isUserHasBot = me?.registerInBot;
  const [sendFileInChatBot] = useSendFileInChatBotMutation();
  const preparedFiles = files?.reduce((acc: IDownloadFiles[], current: IFile, index: number) => {
    if (current.type === ElementFileType.Cover) {
      acc.push({
        downloadFile: files?.[index + 1],
        coverFile: current,
      });
    } else return acc;
    return acc;
  }, []);

  const handleDownloadFile = (downloadFile: IFile) => {
    if (isUserHasBot) {
      sendFileInChatBot({
        variables: {
          elementFileId: downloadFile.id,
        },
      }).then((res) => {
        if (res.data?.sendFileInChatBot) {
          addToast({
            type: ToastTypeEnum.SUCCESS,
            text: 'Отправим файл тебе в бот',
          });
        } else {
          addToast({
            type: ToastTypeEnum.ERROR,
            text: 'Произошла ошибка при скачивании файла',
          });
        }
      });
    } else {
      window.open(downloadFile.file.url);
    }
  };

  return (
    <Stack direction='row' spacing={2} justifyContent='space-between'>
      {preparedFiles?.map((file: IDownloadFiles) => <PromoCard image={file.coverFile.file.url} onClick={() => handleDownloadFile(file.downloadFile)} />)}
    </Stack>
  );
};

export default DownloadFiles;
