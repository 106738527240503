/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/student_lvl_14.gltf
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import startAnimation from '../helpers/startAnimation';

/*
eslint-disable react/no-unknown-property
*/

const Student = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/student_lvl_14.gltf');
  const { actions, names } = useAnimations(animations, group);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    startAnimation(actions, names);
  }, [actions, names]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <group name='ARMA_student_lvl_14' position={[0.803, 0, 0.5]} rotation={[0, -1.061, 0]}>
          <primitive object={nodes.pelvis} />
          <primitive object={nodes.neutral_bone} />
          <group name='MESH_cloth'>
            <skinnedMesh castShadow name='Plane007' geometry={nodes.Plane007.geometry} material={materials.mantle_black} skeleton={nodes.Plane007.skeleton} />
            <skinnedMesh castShadow name='Plane007_1' geometry={nodes.Plane007_1.geometry} material={materials.blue} skeleton={nodes.Plane007_1.skeleton} />
            <skinnedMesh castShadow name='Plane007_2' geometry={nodes.Plane007_2.geometry} material={materials.yellow} skeleton={nodes.Plane007_2.skeleton} />
          </group>
          <skinnedMesh
            castShadow
            name='MESH_student_lvl_14'
            geometry={nodes.MESH_student_lvl_14.geometry}
            material={materials['Texture_capibara.005']}
            skeleton={nodes.MESH_student_lvl_14.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

export default Student;

useGLTF.preload('/student_lvl_14.gltf');
