import { KeyboardArrowDown } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { IconButton, IconButtonProps, SxProps } from '@mui/material';
import { FC } from 'react';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMoreIcon = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ExpandMoreButton: FC<{ isOpen: boolean; onClick?: () => any; sx?: SxProps }> = ({ isOpen, onClick, sx }) => (
  <ExpandMoreIcon expand={isOpen} onClick={onClick} sx={{ position: 'absolute', ...sx }}>
    <KeyboardArrowDown sx={(theme) => ({ color: theme.palette.mode === 'light' ? 'base.800' : 'base.100' })} />
  </ExpandMoreIcon>
);

export default ExpandMoreButton;

ExpandMoreButton.defaultProps = {
  sx: {},
  onClick: undefined,
};
