import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import TextFieldControl from 'components/form/TextFieldControl';
import { Mood, MoodBad } from '@mui/icons-material';
import { CourseSubject } from '@generated/graphql';
import { useOutletContext } from 'react-router-dom';

export interface IFeedbackProps {}

const MoodButton = ({ mood, subject }: { mood: 'good' | 'bad'; subject: CourseSubject }) => (
  <IconButton
    sx={(theme) => ({
      borderRadius: 1.5,
      backgroundColor: 'baseShift.500',
      '& > .MuiSvgIcon-root': {
        color: theme.palette.base[300],
      },
      '&:hover': {
        backgroundColor: mood === 'good' ? theme.palette[subject].main : theme.palette.error.main,
        '& > .MuiSvgIcon-root': {
          color: theme.palette.base[100],
        },
      },
    })}
  >
    {mood === 'good' ? <Mood /> : <MoodBad />}
  </IconButton>
);

const Feedback = () => {
  const { subject } = useOutletContext<{ subject: CourseSubject }>();
  return (
    <Card component={Stack} spacing={2}>
      <Typography variant='text3'>Нам очень важно твое мнение по данному уроку</Typography>
      <Box sx={{ position: 'relative' }} component='div'>
        <TextFieldControl name='elements' placeholder='Введите ответ' multiline rows={12} sx={{ '& > div': { paddingBottom: 8 } }} />
        <Stack direction='row' spacing={1} sx={{ position: 'absolute', bottom: 14, right: 12 }}>
          <MoodButton mood='good' subject={subject} />
          <MoodButton mood='bad' subject={subject} />
        </Stack>
      </Box>
    </Card>
  );
};

export default Feedback;
