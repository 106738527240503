import { ButtonGroup, IconButton } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

interface SliderButtonsProps {
  totalSlides: number;
  setCurrentSlide: Dispatch<SetStateAction<number>>;
  shift: number;
}

const SliderButtons: FC<SliderButtonsProps> = ({ setCurrentSlide, totalSlides, shift }) => {
  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => {
      if (prevSlide < totalSlides - shift) return prevSlide + 1;
      return 0;
    });
  };

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) => {
      if (prevSlide > 0) return prevSlide - 1;
      return totalSlides - shift;
    });
  };
  return (
    <ButtonGroup
      sx={{
        columnGap: 2,
        '& > button': {
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <IconButton onClick={goToPrevSlide} variant='contained' size='small'>
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        variant='contained'
        size='small'
        onClick={goToNextSlide}
        sx={{
          '& > img': {
            transform: 'scale(-1, 1)',
          },
        }}
      >
        <KeyboardArrowRight />
      </IconButton>
    </ButtonGroup>
  );
};

export default SliderButtons;
