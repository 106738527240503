import { Box, Card, Stack, Typography } from '@mui/material';
import InfoBanner from 'components/dashboard/InfoBanner';
import PromoCard from 'components/dashboard/PromoCard';
import GiftImage from 'assets/images/common/gift.png';
import CartImage from 'assets/images/common/telega.png';
import { useNavigate, useParams } from 'react-router-dom';
import Blocks from 'pages/Course/components/Blocks';
import { CourseCardType, useCardsQuery, useCourseQuery } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import Character from 'pages/Course/components/Character';
import CourseHeader from 'components/dashboard/CourseHeader';
import React, { useContext, useMemo } from 'react';
import { ModalContext, ModalType } from 'context/modalContext';
import CourseSlider, { CardType } from 'pages/Course/components/CourseSlider';
import _ from 'lodash';
import ROUTES from 'constants/routes';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import CourseColorButton from 'components/buttons/CourseColorButton';
import useTelegram from 'hooks/useTelegram';
import { checkIsUserCanBuyBlock } from 'hooks/useBlockData';
import { ArrowForward } from '@mui/icons-material';

type CoursePageParams = { courseId: string };

const CoursePage = () => {
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { handleOpen } = useContext(ModalContext);
  const { isTelegram } = useTelegram();
  const params = useParams<CoursePageParams>();
  const courseId = params.courseId as string;

  const { data, loading, error } = useCourseQuery({
    variables: {
      courseId,
    },
    fetchPolicy: 'network-only',
  });

  const { data: cardsData, loading: cardsLoading } = useCardsQuery({
    variables: {
      courseId: courseId!,
    },
  });

  const course = data?.course;
  const subject = course?.subject;
  const blocks = course?.blocks;
  const courseProgress = data?.courseStatistic?.progress ? Math.floor(data.courseStatistic.progress * 100) : 0;
  const isNotAllBlocksBought = course?.blocks?.some((block) => checkIsUserCanBuyBlock(block.unavailableReason));

  const availableUserCards = cardsData?.availableCourseCards;
  const advertisingCards = cardsData?.course?.advertising?.map((i) => i.card);

  const openHelpModal = () => {
    handleOpen({
      subject: subject!,
      title: '',
      modalType: ModalType.Placeholder,
      bodyText: 'Напиши нам, если возникли вопросы',
      actionButton: { text: `Написать в @${process.env.REACT_APP_BOT_NAME}` },
      mainButton: { text: 'Закрыть' },
    });
  };

  const openPromoModal = () => {
    handleOpen({
      subject: subject!,
      title: '',
      modalType: ModalType.Placeholder,
      bodyText: 'Вся информация у администратора, нажимай кнопку «Узнать»',
      actionButton: { text: 'Узнать' },
      mainButton: { text: 'Закрыть' },
    });
  };

  const handleBuyFullCourse = () => {
    if (isNotAllBlocksBought) {
      navigate(`/${ROUTES.PAYMENT}/${courseId}`);
    } else {
      addToast({ type: ToastTypeEnum.SUCCESS, text: 'Ты уже купил этот курс!' });
    }
  };

  const courseCards = useMemo(() => {
    const advertising: Array<CardType> = [];
    const available: Array<CardType> = [];
    const allCards = _.unionBy(availableUserCards, advertisingCards, 'course.id')?.filter((card) => card.course.id !== courseId);
    allCards?.forEach((card) => {
      if (card.type === CourseCardType.Advertising) advertising.push(card);
      else available.push(card);
    });
    return { advertising, available };
  }, [advertisingCards, availableUserCards]);

  const changePlatformText = isTelegram ? 'Открыть веб-версию платформы' : 'Открыть платформу в Telegram';

  const toggleChangePlatform = () => {
    if (isTelegram) {
      window.open(`${process.env.REACT_APP_PLATFORM_URL}/course/${courseId}`);
    } else {
      window.open(process.env.REACT_APP_BOT_URL);
    }
  };

  if (loading || cardsLoading) return <CircularLoading />;
  if (error?.message) return <Typography>{error?.message}</Typography>;
  return (
    <Box component='div'>
      <Stack spacing={2.5}>
        <CourseHeader subject={subject} />
        <Character subject={subject} courseProgress={courseProgress} />
        <Card>
          <Stack sx={{ mb: 4 }} spacing={1.5}>
            <Box component='div' sx={{ mx: -2, '& .slick-list': { px: 2 }, '& .slick-slide': { mr: 2 } }}>
              <Blocks blocks={blocks} />
            </Box>
            {isNotAllBlocksBought && (
              <CourseColorButton variant='contained' subject={subject} onClick={handleBuyFullCourse} fullWidth>
                Открыть все уроки
              </CourseColorButton>
            )}
          </Stack>
          {!!courseCards.available?.length && <CourseSlider courses={courseCards.available} header='Мои курсы' />}
          <Typography variant='h1' mb={1.5}>
            Задай вопрос
            <br />
            своему наставнику
          </Typography>
          <InfoBanner icon={<ArrowForward />} text={'Поможем тебе решить\nлюбой вопрос'} onClick={openHelpModal} containerSx={{ mb: 3.5 }} />
          {!!courseCards.advertising?.length && <CourseSlider courses={courseCards.advertising} header={'А также\nпосмотри'} />}
          <Stack direction='row' spacing={1.3} justifyContent='space-between' sx={{ mb: 1.5 }}>
            <PromoCard text='Приведи друга и получи скидку' image={GiftImage} onClick={openPromoModal} />
            <PromoCard text='Приобрести курс' image={CartImage} onClick={handleBuyFullCourse} />
          </Stack>
          <InfoBanner icon={<ArrowForward />} text={changePlatformText} onClick={toggleChangePlatform} />
        </Card>
      </Stack>
    </Box>
  );
};

export default CoursePage;
