import { useUserCourseCardsQuery } from '@generated/graphql';
import { Card, Stack, Typography } from '@mui/material';
import { BackButton } from 'components';
import { useNavigate } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import CourseCard from './components/CourseCard';
import BackgroundLayout from '../../layouts/BackgroundLayout';

const UserCourseListPage = () => {
  const navigate = useNavigate();
  const { data, loading } = useUserCourseCardsQuery();

  const availableUserCards = data?.availableCourseCards;
  const advertisingCourseCards = data?.userAdvertisingCourseCard;

  const navigateBack = () => navigate(-1);

  if (loading) return <CircularLoading />;

  return (
    <BackgroundLayout subject='defaultColor' backgroundType='background'>
      <Card>
        <BackButton text='Назад' navigate={navigateBack} sx={{ mb: 2.5 }} />
        <Typography variant='h1' component='h2' sx={{ mb: 1.5 }}>
          Мои курсы
        </Typography>
        <Stack sx={{ mb: 3.5 }} spacing={2}>
          {availableUserCards?.map((card) => <CourseCard card={card} />)}
        </Stack>
        {!!advertisingCourseCards?.length && (
          <Typography variant='h1' component='h2' sx={{ mb: 1.5 }}>
            А также посмотри
          </Typography>
        )}
        <Stack spacing={2}>{advertisingCourseCards?.map((card) => <CourseCard card={card} />)}</Stack>
      </Card>
    </BackgroundLayout>
  );
};

export default UserCourseListPage;
