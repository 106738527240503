import ROUTES from 'constants/routes';
import { UnavailableReason } from '@generated/graphql';
import { useNavigate } from 'react-router-dom';

interface UseBlockDataParams {
  block:
    | {
        id: string;
        unavailableReason?: UnavailableReason | null | undefined;
        courseId: string;
      }
    | undefined
    | null;
}

export const checkIsUserCanBuyBlock = (unavailableReason: UnavailableReason | null | undefined) =>
  unavailableReason === UnavailableReason.NotTrial || unavailableReason === UnavailableReason.NotPurchased;

const useBlockData = ({ block }: UseBlockDataParams) => {
  const navigate = useNavigate();

  const isUserHasBlock = !block?.unavailableReason || block?.unavailableReason === UnavailableReason.TimeExpired;

  const onNavigateToPurchaseBlock = () => navigate(`/${ROUTES.PAYMENT}/${block?.courseId}/${block?.id}`);

  return { onNavigateToPurchaseBlock, isUserHasBlock };
};

export default useBlockData;
