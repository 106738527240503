import ChillModel from 'models/Chill_lvl_0_emb';
import Baby from 'models/Baby_1_lvl_1';
import BeginnerModel from 'models/Beginner_2_lvl_4_emb';
import Explorer from 'models/Explorer_2_lvl_6';
import BStudent from 'models/B_student_2_lvl_8';
import AStudentModel from 'models/A_student_2_lvl_10_emb';
import ApplicantLvl13 from 'models/Applicant_lvl_13_emb';
import Student from 'models/Student_lvl_14';
import Expert from 'models/Expert_2_lvl_16';
import LegendLvl19 from 'models/Legend_lvl_19_emb';

enum CapybaraType {
  Chill = 'Chill',
  Baby1 = 'Baby1',
  Baby2 = 'Baby2',
  Beginner1 = 'Beginner1',
  Beginner2 = 'Beginner2',
  Explorer1 = 'Explorer1',
  Explorer2 = 'Explorer2',
  BStudent1 = 'BStudent1',
  BStudent2 = 'BStudent2',
  AStudent1 = 'AStudent1',
  AStudent2 = 'AStudent2',
  ToughGuy1 = 'ToughGuy1',
  ToughGuy2 = 'ToughGuy2',
  Applicant = 'Applicant',
  Student = 'Student',
  Expert1 = 'Expert1',
  Expert2 = 'Expert2',
  LabTechnician = 'LabTechnician',
  Scientist = 'Scientist',
  Legend = 'Legend',
}

const CAPYBARA_NAME = {
  [CapybaraType.Chill]: 'На чилле',
  [CapybaraType.Baby1]: 'Малышка',
  [CapybaraType.Baby2]: 'Малышка',
  [CapybaraType.Beginner1]: 'Начинашка',
  [CapybaraType.Beginner2]: 'Начинашка',
  [CapybaraType.Explorer1]: 'Исследователь',
  [CapybaraType.Explorer2]: 'Исследователь',
  [CapybaraType.BStudent1]: 'Хорошист',
  [CapybaraType.BStudent2]: 'Хорошист',
  [CapybaraType.AStudent1]: 'Отличник',
  [CapybaraType.AStudent2]: 'Отличник',
  [CapybaraType.ToughGuy1]: 'Крутыш',
  [CapybaraType.ToughGuy2]: 'Крутыш',
  [CapybaraType.Applicant]: 'Абитуриент',
  [CapybaraType.Student]: 'Студент',
  [CapybaraType.Expert1]: 'Эксперт',
  [CapybaraType.Expert2]: 'Эксперт',
  [CapybaraType.LabTechnician]: 'Лаборант',
  [CapybaraType.Scientist]: 'Ученый',
  [CapybaraType.Legend]: 'Легенда',
};

const CAPYBARA_MODEL = {
  [CapybaraType.Chill]: ChillModel,
  [CapybaraType.Baby1]: Baby,
  [CapybaraType.Baby2]: Baby,
  [CapybaraType.Beginner1]: BeginnerModel,
  [CapybaraType.Beginner2]: undefined,
  [CapybaraType.Explorer1]: Explorer,
  [CapybaraType.Explorer2]: undefined,
  [CapybaraType.BStudent1]: BStudent,
  [CapybaraType.BStudent2]: undefined,
  [CapybaraType.AStudent1]: AStudentModel,
  [CapybaraType.AStudent2]: undefined,
  [CapybaraType.ToughGuy1]: undefined,
  [CapybaraType.ToughGuy2]: undefined,
  [CapybaraType.Applicant]: ApplicantLvl13,
  [CapybaraType.Student]: Student,
  [CapybaraType.Expert1]: Expert,
  [CapybaraType.Expert2]: undefined,
  [CapybaraType.LabTechnician]: undefined,
  [CapybaraType.Scientist]: undefined,
  [CapybaraType.Legend]: LegendLvl19,
};
export { CapybaraType, CAPYBARA_NAME, CAPYBARA_MODEL };
