import RadioButtonControl, { IRadioButtonProps } from 'components/form/RadioButtonControl';
import { FC } from 'react';
import { CourseSubjectColor } from 'types/common';
import CheckboxGroupControl from 'components/form/CheckboxGroupControl';
import MatchingSelect from 'components/ElementsGenerator/inputs/MatchingSelect';
import File from 'components/ElementsGenerator/elements/File';
import { QuestionType } from '@generated/graphql';
import TextAnswer from 'components/ElementsGenerator/inputs/TextAnswer';

const ANSWER_COMPONENT = {
  [QuestionType.TextAnswers]: TextAnswer,
  [QuestionType.SingleAnswer]: RadioButtonControl,
  [QuestionType.FewAnswers]: CheckboxGroupControl,
  [QuestionType.FileAnswer]: File,
  [QuestionType.Matching]: MatchingSelect,
};

interface CommonProps {
  name: string;
  questionType: QuestionType;
  subject: CourseSubjectColor;
  onlyNumbersInput?: boolean;
}

type ConditionalProps<T = QuestionType> = T extends QuestionType.TextAnswers ? { placeholder?: string; elementParts?: never } : { elementParts: any };

type AnswerComponentProps = CommonProps & ConditionalProps;

const AnswerComponent: FC<AnswerComponentProps> = ({ questionType, ...rest }) => {
  if (questionType) {
    const Component = ANSWER_COMPONENT[questionType] as any;
    return (
      <Component
        key={`${questionType}-${rest.name}`}
        questionType={questionType}
        handleChange={(value: string, onChange: any) => (rest.onlyNumbersInput ? onChange(value.replace(/\D/g, '').trim()) : onChange(value))}
        itemList={rest.elementParts as IRadioButtonProps[]}
        {...rest}
      />
    );
  }
  return null;
};

export default AnswerComponent;

AnswerComponent.defaultProps = {
  onlyNumbersInput: false,
};
