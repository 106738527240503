import Scene from 'pages/Course/components/Scene';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { CourseSubject } from '@generated/graphql';
import { CapybaraType } from 'constants/capybara';
import useTelegram, { webApp } from 'hooks/useTelegram';

const PlaceholderModal: FC<{
  open: boolean;
  onClose: () => void;
  subject: CourseSubject;
  closeButtonText: string;
  mainButtonText: string;
  headerText: string;
}> = ({ open, onClose, subject, closeButtonText, mainButtonText, headerText }) => {
  const { tg } = useTelegram();
  const isTelegram = webApp.platform !== 'unknown';
  const handleClick = () => {
    if (isTelegram) tg.close();
    else window.open(`https://t.me/${process.env.REACT_APP_BOT_NAME}`);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant='h3' textAlign='center'>
          {headerText}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          component='div'
          sx={{
            height: '150px',
            zIndex: 0,
          }}
        >
          <Scene capybaraType={CapybaraType.Explorer1} withoutFloor camera={{ position: [-6, 0, -0.5], fov: 42 }} capybaraPosition={[0, -1.5, -0.5]} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack sx={{ width: '100%' }} spacing={1.3}>
          <Button variant='contained' color='primary' size='small' fullWidth subject={subject} onClick={handleClick}>
            {mainButtonText}
          </Button>
          <Button
            color='secondary'
            size='small'
            sx={{ textDecoration: 'underline', '&:hover': { textDecoration: 'underline' } }}
            fullWidth
            onClick={onClose}
            subject={subject}
          >
            {closeButtonText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
export default PlaceholderModal;
