import { Typography } from '@mui/material';

export interface IHeaderProps {
  title?: string | null;
}

const Header = ({ title }: IHeaderProps) => (
  <Typography sx={{ whiteSpace: 'pre-wrap' }} variant='h2'>
    {title}
  </Typography>
);

export default Header;
