import PromoSubjectCard from 'components/dashboard/PromoSubjectCard';
import React, { useContext } from 'react';
import { CourseCardType, useGetTrialMutation, UserCourseCardsQuery } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useNavigate } from 'react-router-dom';

interface CourseCardProps {
  card: UserCourseCardsQuery['availableCourseCards'][number] | NonNullable<UserCourseCardsQuery['userAdvertisingCourseCard']>[number];
}

const CourseCard = ({ card }: CourseCardProps) => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);

  const [getTrial] = useGetTrialMutation();

  const goToCourse = (courseId: string, cardType: CourseCardType) => {
    if (cardType === CourseCardType.Advertising) {
      getTrial({
        variables: {
          coursesIds: [courseId],
        },
      })
        .then(() => navigate(`/course/${courseId}`))
        .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
    } else navigate(`/course/${courseId}`);
  };
  return (
    <PromoSubjectCard
      title={card.course.title}
      description={card.description}
      buttonText='На страницу курса'
      containerSx={{ minHeight: 162, height: 'auto' }}
      subject={card.course.subject}
      backgroundImage={card.useDefaultCover ? card.course.coverFile?.url : card.coverFile?.url}
      handleButtonClick={() => goToCourse(card.course.id, card.type)}
    />
  );
};

export default CourseCard;
