import { webApp } from 'hooks/useTelegram';

const cloudStorage = webApp.CloudStorage;

const getItem = (key: string) =>
  new Promise((resolve, reject) => {
    cloudStorage?.getItem(key, (error: null, value: string) => {
      if (!error) {
        resolve(value);
      } else {
        reject(error);
      }
    });
  });

const setItem = (key: string, value: string) =>
  new Promise((resolve, reject) => {
    cloudStorage?.setItem(key, value, (error: null, state: boolean) => {
      if (!error && state) {
        resolve(state);
      } else {
        reject(error);
      }
    });
  });

const removeItem = (key: string) =>
  new Promise((resolve, reject) => {
    cloudStorage?.removeItem(key, (error: null, state: boolean) => {
      if (!error && state) {
        resolve(state);
      } else {
        reject(error);
      }
    });
  });

export { getItem, removeItem, setItem };
