// import { Stack, Box } from '@mui/material';
// import Banner from './components/Banner';
// import Header from './components/Header';
// import Greetings from './components/Greetings';
// import Numbers from './components/Numbers';
// import Advantages from './components/Advantages';
// import Courses from './components/Courses';
// import About from './components/About';
// import Description from './components/Description';
// import Character from './components/Character';
// import FAQ from './components/Faq';
// import Contacts from './components/Contacts';
// import Footer from './components/Footer';

import { useEffect } from 'react';

const Landing = () => {
  useEffect(() => {
    const landingUrl = process.env.REACT_APP_LANDING_URL;
    if (landingUrl) {
      window.location.replace(landingUrl);
    }
  }, []);
  return <div />;
  // return (
  //   <Stack>
  //     <Banner />
  //     <Box
  //       component='div'
  //       sx={{
  //         boxSizing: 'border-box',
  //         mx: 'auto',
  //         px: 2.5,
  //         width: '100%',
  //         overflow: 'hidden',
  //         '@media (min-width: 1020px)': {
  //           width: 1019,
  //           px: 4
  //         },
  //         '@media (min-width: 1340px)': {
  //           width: 1320,
  //           px: 8.5
  //         },
  //         '@media (min-width: 1440px)': {
  //           width: 1440
  //         }
  //       }}
  //     >
  //       <Header />
  //       <Greetings />
  //       <Numbers />
  //       <Advantages />
  //       <Courses />
  //       <About />
  //       <Description />
  //       <Character />
  //       <FAQ />
  //       <Contacts />
  //     </Box>
  //     <Footer />
  //   </Stack>
  // )
};

export default Landing;
