import { Card, LinearProgress, Typography } from '@mui/material';
import { FC } from 'react';
import { ProgressStatistic } from '@generated/graphql';
import { useOutletContext } from 'react-router-dom';
import { IOutletContext } from 'types/common';

const LectureProgressCard: FC<{ blockStatistic: ProgressStatistic }> = ({ blockStatistic }) => {
  const { subject } = useOutletContext<IOutletContext>();

  return (
    <Card
      sx={{
        backgroundColor: 'baseShift.400',
        boxShadow: 0,
        mb: 2.5,
      }}
    >
      <Typography variant='h2' sx={{ mb: 1.5, color: 'baseShift.text' }}>
        Просмотрено лекций
      </Typography>
      <Typography
        variant='h1'
        sx={{
          color: 'baseShift.text',
          mb: 1.5,
          fontWeight: 500,
        }}
      >
        {`${blockStatistic.lectureScore.score}/${blockStatistic.lectureScore.maxScore}`}
      </Typography>
      <LinearProgress
        value={(100 / blockStatistic.lectureScore.maxScore) * (blockStatistic?.lectureScore?.score || 0)}
        coloringType='gradient'
        subject={subject}
      />
    </Card>
  );
};

export default LectureProgressCard;
