import { Box, Typography } from '@mui/material';
import { FC } from 'react';

type PromoCardProps = {
  text?: string;
  image: string;
  fileUrl?: string;
  onClick?: () => any;
};
const PromoCard: FC<PromoCardProps> = ({ text, image, fileUrl, onClick }) => (
  <Box
    component={fileUrl ? 'a' : 'div'}
    download
    href={fileUrl}
    target='_blank'
    onClick={onClick}
    sx={{
      cursor: (fileUrl || onClick) && 'pointer',
      bgcolor: 'baseShift.400',
      width: 155,
      padding: 2,
      height: 186,
      borderRadius: 4,
      backgroundImage: `url(${image})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom 4px right 10px',
      backgroundSize: '70%',
    }}
  >
    {text && <Typography variant='text4'>{text}</Typography>}
  </Box>
);

export default PromoCard;

PromoCard.defaultProps = {
  fileUrl: undefined,
  text: undefined,
  onClick: undefined,
};
