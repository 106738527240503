import { QuestionType } from '@generated/graphql';
import { Answer } from 'pages/Lesson/Homework/types';

const validateAnswerType = (questionType: QuestionType, answer: Answer) => {
  switch (questionType) {
    case QuestionType.TextAnswers: {
      if (!(typeof answer === 'object' && 'text' in answer)) {
        throw new Error('Invalid answer type for TextAnswers');
      }
      break;
    }
    case QuestionType.FewAnswers: {
      if (!(typeof answer === 'object' && !('checked' in answer))) {
        throw new Error('Invalid answer type for FewAnswers');
      }
      break;
    }
    case QuestionType.Matching: {
      if (!Array.isArray(answer)) {
        throw new Error('Invalid answer type for Matching');
      }
      break;
    }
    case QuestionType.SingleAnswer: {
      if (!(typeof answer === 'object' && 'checked' in answer)) {
        throw new Error('Invalid answer type for SingleAnswer');
      }
      break;
    }
    case QuestionType.FileAnswer: {
      if (!(answer instanceof FileList)) {
        throw new Error('Invalid answer type for FileAnswer');
      }
      break;
    }
    default: {
      throw new Error('Unsupported question type');
    }
  }
};

export default validateAnswerType;
