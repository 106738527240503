import { ElementType } from '@generated/graphql';
import { Header, Text, TaskContainer, Video } from 'components/ElementsGenerator/';
import DownloadFiles from 'components/ElementsGenerator/elements/DownloadFiles';
import Feedback from 'components/ElementsGenerator/elements/Feedback';
import ExerciseLink from 'components/ElementsGenerator/elements/ExerciseLink';
import { ElementGeneratorProps } from './ElementsGenerator.types';

const ElementsGenerator = (props: ElementGeneratorProps) => {
  const { type, item } = props;
  switch (type) {
    case ElementType.Question: {
      return <TaskContainer {...item} />;
    }
    case ElementType.Heading: {
      return <Header {...item} />;
    }
    case ElementType.Text: {
      return <Text {...item} />;
    }
    case ElementType.Video: {
      return <Video {...item} />;
    }
    case ElementType.File: {
      return <DownloadFiles {...item} />;
    }
    case ElementType.Feedback: {
      return <Feedback />;
    }
    case ElementType.ExerciseLink: {
      return <ExerciseLink {...item} />;
    }
    default: {
      return null;
    }
  }
};

export default ElementsGenerator;
