import { useContext } from 'react';
import { useUpdateUserDataMutation, useUserProfileQuery } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { Avatar, Card, Stack, Typography } from '@mui/material';
import { BackButton } from 'components';
import { Form } from 'components/form/Form';
import TextFieldControl from 'components/form/TextFieldControl';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { LoadingButton } from '@mui/lab';
import SelectControl from 'components/form/SelectControl';
import DatePickerControl from 'components/form/DatePickerControl';
import AvatarPlaceholderImg from 'assets/images/common/avatar-placeholder.png';
import { format, isValid } from 'date-fns';
import { useUsername } from 'helpers/main';
import BackgroundLayout from '../../layouts/BackgroundLayout';

interface UserProfileForm {
  avatarFile?: string;
  comment: number | null;
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  birthDate?: Date | null;
  phoneNumber?: string | null;
}

const USER_ROLE_LIST = ['Родитель', 'Ученик'];

const UserProfilePage = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const { data, loading } = useUserProfileQuery();
  const user = data?.me;
  const [updateUser, { loading: updateUserLoading }] = useUpdateUserDataMutation();

  const form = useForm<UserProfileForm>({
    values: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      middleName: user?.middleName,
      phoneNumber: user?.phoneNumber,
      comment: user?.comment ? USER_ROLE_LIST.indexOf(user.comment) + 1 : null,
      birthDate: user?.birthDate ? new Date(user.birthDate) : null,
    },
  });
  const { handleSubmit } = form;

  const handleNavigateBack = () => navigate(-1);

  const onSubmit = (formData: UserProfileForm) => {
    const { birthDate, phoneNumber, comment, ...rest } = formData;
    const preparedBirthDate = birthDate && isValid(birthDate) ? format(birthDate, 'yyyy-MM-dd') : birthDate;
    const userRole = comment ? USER_ROLE_LIST[comment - 1] : null;

    updateUser({
      variables: {
        input: {
          ...rest,
          comment: userRole,
          birthDate: preparedBirthDate,
        },
      },
    })
      .then(() => addToast({ type: ToastTypeEnum.SUCCESS }))
      .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
  };

  const avatarImg = user?.avatarFile?.url || AvatarPlaceholderImg;
  const username = useUsername(user);

  if (loading) return <CircularLoading />;

  return (
    <BackgroundLayout subject='defaultColor' backgroundType='background'>
      <Stack spacing={2.5}>
        <Card>
          <BackButton navigate={handleNavigateBack} text='Назад' />
          <Stack spacing={1.5} justifyContent='center' alignItems='center'>
            <Avatar sx={{ width: 120, height: 120 }} src={avatarImg} />
            {username && (
              <Typography variant='h5' component='p'>
                {username}
              </Typography>
            )}
          </Stack>
        </Card>
        <Card>
          <Form form={form}>
            <Stack spacing={2} sx={{ mb: 3.5 }}>
              <TextFieldControl name='lastName' placeholder='Фамилия' />
              <TextFieldControl name='firstName' placeholder='Имя' rules={{ required: true }} />
              <TextFieldControl name='middleName' placeholder='Отчество' />
            </Stack>
            <Stack spacing={3.5}>
              <DatePickerControl name='birthDate' />
              <TextFieldControl name='phoneNumber' placeholder='Номер телефона' disabled maskType='phone' />
              <SelectControl name='comment' list={USER_ROLE_LIST} rules={{ required: true }} />
              <LoadingButton variant='contained' subject='defaultColor' color='secondary' onClick={handleSubmit(onSubmit)} loading={updateUserLoading}>
                Сохранить
              </LoadingButton>
            </Stack>
          </Form>
        </Card>
      </Stack>
    </BackgroundLayout>
  );
};

export default UserProfilePage;
