import { createTheme, PaletteMode } from '@mui/material';
import typography from './typography';
import lightTheme from './lightTheme';
import darkTheme from './darkTheme';
import colors from './colors';
import shadows from './shadows';
import components from './components';

const themesOptions: { [index: string]: object } = {
  light: lightTheme,
  dark: darkTheme,
};

const tg = window.Telegram.WebApp;
const isTg = tg.platform !== 'unknown';

const createAppTheme = (config: { theme: PaletteMode | undefined }) => {
  let palette: any = themesOptions.light;
  if (!config.theme) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
  } else {
    palette = themesOptions[config.theme];
    if (isTg) tg.setHeaderColor(palette.background.default);
  }

  return createTheme({
    palette: { ...palette, ...colors },
    typography,
    shadows,
    components,
  });
};
export default createAppTheme;
