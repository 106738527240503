import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { generatePath, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { CourseSubject } from '@generated/graphql';
import IMAGES from 'constants/images';
import CircularLoading from 'components/CircularLoading';

const SuccessPage = () => {
  const navigate = useNavigate();
  const context = useOutletContext<{ setSubject: any; subject: CourseSubject | 'defaultColor'; themeLoading: boolean }>();
  const subject = context.subject === 'defaultColor' ? CourseSubject.Biology : context.subject;

  const [searchParams] = useSearchParams();
  const courseId = searchParams.get('courseId') as string;
  const blockId = searchParams.get('blockId') as string;

  const params = useParams<{ courseId: string; subject: CourseSubject }>();

  if (context.themeLoading) return <CircularLoading />;

  return (
    <Box sx={{ height: '100vh' }} component='div'>
      <Card>
        <Stack spacing={3}>
          <Typography variant='h2'>С нетерпением ждем тебя на платформе!</Typography>
          <Box component='div' sx={{ '& > img': { width: 125, mx: 'auto', display: 'block' } }}>
            <img src={IMAGES[subject].approve} alt='success' />
          </Box>
          <Button
            subject={subject}
            variant='contained'
            color='secondary'
            endIcon={<ArrowForward />}
            fullWidth
            onClick={() =>
              navigate(
                generatePath('/course/:courseId/:blockId?', { courseId: (courseId || params.courseId) as string | null, blockId: blockId as string | null }),
              )
            }
          >
            {blockId ? 'Перейти к блоку' : 'Перейти к курсу'}
          </Button>
        </Stack>
      </Card>
    </Box>
  );
};

export default SuccessPage;
