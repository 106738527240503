import Scene from 'pages/Course/components/Scene';
import { CapybaraType } from 'constants/capybara';
import { Box } from '@mui/material';

const Capybara = () => (
  <Box
    component='div'
    sx={(theme) => ({
      height: 200,
      background: `radial-gradient(50% 50% at 50% 50%, 
            ${theme.palette.defaultColor.main} 0%, ${theme.palette.mode === 'light' ? '#fff' : '#152746'} 100%)`,
      position: 'relative',
      p: 0,
    })}
  >
    <Scene
      capybaraType={CapybaraType.Beginner1}
      camera={{ position: [-8, 0.5, -0.5], fov: 35 }}
      capybaraPosition={[0, -1.6, -0.3]}
      floorPosition={[-0.5, -1.6, -0.6]}
    />
  </Box>
);

export default Capybara;
