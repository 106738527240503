import React from 'react';
import { SxProps, Typography } from '@mui/material';

export interface ITextProps {
  content: string;
  sx?: SxProps;
}

const Text = ({ content, sx }: ITextProps) => (
  <Typography
    variant='text3'
    sx={{ whiteSpace: 'pre-wrap', '& p': { m: 0, mb: 1 }, '& a': { wordBreak: 'break-all', textDecoration: 'underline' }, ...sx }}
    dangerouslySetInnerHTML={{
      __html: content?.replaceAll('color:#000000;', ''),
    }}
  />
);
export default Text;

Text.defaultProps = {
  sx: undefined,
};
