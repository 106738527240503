import React, { FC } from 'react';
import { Avatar } from '@mui/material';

export type NumberCircleStatusType = 'missed' | 'answered' | 'error';

interface IProps {
  number: string | number;
  status: NumberCircleStatusType;
}

export enum TaskStatus {
  missed = 'missed',
  answered = 'answered',
  error = 'error',
}

const STATUS = {
  [TaskStatus.missed]: {
    bgcolor: 'baseShift.500',
    boxShadow: 1,
  },
  [TaskStatus.answered]: {
    bgcolor: 'baseShift.400',
  },
  [TaskStatus.error]: {
    bgcolor: 'error.light',
  },
};

const NumberCircle: FC<IProps> = ({ number, status }) => (
  <Avatar
    sx={{
      ...STATUS[status],
      color: 'baseShift.100',
      cursor: 'pointer',
    }}
  >
    {number}
  </Avatar>
);

export default NumberCircle;
