declare global {
  interface Window {
    Telegram: any;
  }
}

export const webApp = window.Telegram.WebApp;

const useTelegram = () => {
  const tg = webApp;
  const tgCloudStorage = tg.CloudStorage;
  const isTelegram = webApp.platform !== 'unknown';

  return { tg, tgCloudStorage, isTelegram };
};

export default useTelegram;
