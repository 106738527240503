import { FormControl, Select, MenuItem, SelectChangeEvent, SxProps } from '@mui/material';
import { useController } from 'react-hook-form';
import { FC } from 'react';
import generateList from 'components/form/generateList';
import { CourseSubjectColor } from 'types/common';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

type SelectProps = {
  name: string;
  list: { label: string; value: any }[] | string[];
  subject?: CourseSubjectColor;
  elementPartId?: string;
  disabled?: boolean;
  sx?: SxProps;
  rules?: RegisterOptions;
};

const SelectControl: FC<SelectProps> = ({ name, list, subject = 'defaultColor', elementPartId, disabled, sx, rules }) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    rules,
  });
  return (
    <FormControl fullWidth error={!!error}>
      <Select
        variant='outlined'
        sx={sx}
        disabled={disabled}
        value={field.value?.text || field.value}
        onChange={(event: SelectChangeEvent) => {
          if (elementPartId) field.onChange({ text: event.target.value, elementPartId });
          else field.onChange(event.target.value);
        }}
      >
        {list?.map((item, index) => {
          const { label, value } = typeof item === 'string' ? generateList(item, index) : item;
          return (
            <MenuItem value={value} subject={subject} key={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default SelectControl;
