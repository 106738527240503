import { createSvgIcon } from '@mui/material';

const CheckIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='18' height='13' viewBox='0 0 18 13' fill='none'>
    <path d='M6.29167 12.6746L0.875 7.35541L2.77083 5.49371L6.29167 8.95116L15.2292 0.174561L17.125 2.03626L6.29167 12.6746Z' fill='currentColor' />
  </svg>,
  'CheckIcon',
);

export default CheckIcon;
