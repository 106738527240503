import TextFieldControl from 'components/form/TextFieldControl';
import { Form } from 'components/form/Form';
import { FC, useEffect } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Chip, Grid, IconButton, Stack, SvgIcon, Tooltip, useTheme } from '@mui/material';
import { ReactComponent as Attachment } from 'assets/images/attachment.svg';
import { Close } from '@mui/icons-material';

const FileChip: FC<{ file: any; remove: any; index: number }> = ({ file, remove, index }) => {
  const { typography } = useTheme();
  const fileData = file.file;
  return (
    <Grid item xs={3}>
      <Tooltip title={fileData.name}>
        <Chip
          label={fileData.name && fileData.name.length > 8 ? `${fileData.name.slice(0, 8)}...` : fileData.name}
          size='medium'
          onDelete={() => remove(index)}
          deleteIcon={<Close />}
          sx={{
            bgcolor: 'baseShift.400',
            color: 'baseShift.text',
            minWidth: 130,
            ...typography.text7,
          }}
        />
      </Tooltip>
    </Grid>
  );
};

const UserAnswerForm: FC<{ form: UseFormReturn; hideForm: () => void; showForm: boolean }> = ({ form, hideForm, showForm }) => {
  const { control, reset, formState } = form;

  const { fields, update, remove } = useFieldArray({
    name: 'files',
    control,
  });

  useEffect(() => {
    reset({
      message: '',
      files: undefined,
    });
  }, [showForm]);

  const isError = formState.errors.message;

  return (
    <Form form={form}>
      <Stack
        sx={{
          boxShadow: 4,
          borderRadius: '10px',
          px: 1.5,
          py: 1.6,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: isError ? 'error.main' : 'transparent',
        }}
      >
        <Stack direction='row' alignItems='flex-start'>
          <TextFieldControl
            name='message'
            multiline
            minRows={7}
            rules={{ required: true }}
            placeholder='Введи свой комментарий'
            sx={{ '& .MuiOutlinedInput-notchedOutline': { border: 'none' }, '& .MuiInputBase-root': { p: 0 } }}
          />
          <IconButton onClick={hideForm} sx={{ color: 'baseShift.200' }}>
            <Close />
          </IconButton>
        </Stack>

        <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
          <Grid container columnGap={1} rowGap={2}>
            {fields?.length ? fields.map((file: any, index: number) => <FileChip file={file} remove={remove} index={index} />) : null}
          </Grid>
          <IconButton component='label'>
            <input
              type='file'
              style={{ display: 'none' }}
              onChange={(event: any) =>
                update(fields?.length, {
                  file: Array.from(event.target.files)[0],
                })
              }
              multiple
            />
            <SvgIcon component={Attachment} sx={{ color: 'baseShift.text' }} />
          </IconButton>
        </Stack>
      </Stack>
    </Form>
  );
};

export default UserAnswerForm;
