import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import SliderButtons from './SliderButtons';
import { CarouselState, Direction, SliderProps } from './types.d';
import reducer from './helpers';

const getInitialState = (count: number): CarouselState => ({ pos: count - 1, sliding: false, dir: Direction.NEXT });

const Slider: React.FC<SliderProps> = (props) => {
  const { children, shift = 1, itemWidth, containerSx, header, headerProps, swipe } = props;
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  // const [skipTransition, setSkipTransition] = useState<boolean>(false);
  const totalSlides = children.length;

  const [state, dispatch] = React.useReducer(reducer, getInitialState(totalSlides));

  const slide = (dir: Direction) => {
    dispatch({ type: dir, count: totalSlides });
    setTimeout(() => {
      dispatch({ type: 'stopSliding' });
    }, 50);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      slide(Direction.NEXT);
    },
    onSwipedRight: () => slide(Direction.PREV),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  useEffect(() => {
    let slideNumber = currentSlide;
    if (swipe && state.sliding) {
      const isNext = state.dir === Direction.NEXT;
      if (isNext) {
        const isLast = currentSlide === totalSlides - 1;
        if (isLast) {
          slideNumber = 0;
          // setSkipTransition(true);
        } else slideNumber += shift;
      } else {
        const isFirst = currentSlide === 0;
        if (isFirst) {
          slideNumber = totalSlides - 1;
          // setSkipTransition(true);
        } else slideNumber -= shift;
      }
      setCurrentSlide(slideNumber);
    }
  }, [state.dir, state.sliding, itemWidth]);

  // useEffect(() => {
  //   if (skipTransition) {
  //     setTimeout(() => {
  //       setSkipTransition(false);
  //     }, 10);
  //   }
  // }, [skipTransition]);

  return (
    <Box
      component='div'
      sx={{
        position: 'relative',
        overflow: 'hidden',
        ...containerSx,
      }}
    >
      <Box component='div' sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography {...headerProps}>{header}</Typography>
        {totalSlides > 1 && <SliderButtons totalSlides={totalSlides} setCurrentSlide={setCurrentSlide} shift={shift} />}
      </Box>
      <Box
        {...handlers}
        component='div'
        sx={{
          mt: 3,
          display: 'flex',
          columnGap: '20px',
          transition: 'all 250ms ease-in-out',
          position: 'relative',
          transform: `translateX(${-currentSlide * (itemWidth + 20)}px)`,
          '@media (max-width: 699px)': {
            mt: 2.5,
            columnGap: '16px',
            transform: `translateX(${-currentSlide * (itemWidth + 16)}px)`,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default Slider;
