import { CourseCardType, CourseSubject, useGetTrialMutation } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import _ from 'lodash';
import PromoSubjectCard from 'components/dashboard/PromoSubjectCard';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

type CardType = {
  __typename?: 'CourseCardLkDto';
  id: string;
  description: string;
  useDefaultCover: boolean;
  type: CourseCardType;
  link?: string | null;
  coverFile?: { __typename?: 'FileLkDto'; url: string } | null;
  course: {
    __typename?: 'CoursePresentationLkDto';
    id: string;
    title?: string | null;
    subject: CourseSubject;
    coverFile?: { __typename?: 'FileLkDto'; url: string } | null;
  };
};

const OtherCourseCards: FC<{ availableUserCards: CardType[] | undefined; advertisingCards: CardType[] | undefined; courseId: string }> = ({
  courseId,
  availableUserCards,
  advertisingCards,
}) => {
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [getTrial] = useGetTrialMutation();

  const goToCourse = (courseId: string, cardType: CourseCardType) => {
    if (cardType === CourseCardType.Advertising) {
      getTrial({
        variables: {
          coursesIds: [courseId],
        },
      })
        .then(() => navigate(`/course/${courseId}`))
        .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
    } else navigate(`/course/${courseId}`);
  };

  const cards = _.unionBy(availableUserCards, advertisingCards, 'course.id')?.filter((card) => card.course.id !== courseId);

  return cards?.map((card) => (
    <PromoSubjectCard
      title={card.course.title}
      description={card.description}
      buttonText='На страницу курса'
      containerSx={{ mb: 3.5, minHeight: 162, height: 'auto' }}
      subject={card.course.subject}
      backgroundImage={card.useDefaultCover ? card.course.coverFile?.url : card.coverFile?.url}
      onClick={() => goToCourse(card.course.id, card.type)}
    />
  ));
};
export default OtherCourseCards;
