import { Box, Button, Card, Stack, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { ArrowForward } from '@mui/icons-material';
import { CourseSubject } from '@generated/graphql';
import useThemeMode from 'hooks/useThemeMode';

export type PromoSubjectCardProps = {
  title?: string | null;
  backgroundImage?: string;
  description?: string | null;
  buttonText?: string;
  containerSx?: object;
  subject: CourseSubject | undefined;
  handleButtonClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
  icon?: ReactNode;
};

const PromoSubjectCard: FC<PromoSubjectCardProps> = ({
  title,
  handleButtonClick,
  description,
  backgroundImage,
  buttonText,
  containerSx,
  subject,
  onClick,
  icon,
}) => {
  const { isLightTheme } = useThemeMode();

  const background = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: `${subject}.main`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right',
    backgroundSize: 'contain',
  };

  return (
    <Card
      component={Stack}
      onClick={onClick}
      sx={{
        ...background,
        minHeight: 140,
        maxHeight: 162,
        cursor: onClick ? 'pointer' : 'default',
        ...containerSx,
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='h1.2' mb={1.3} color='base.100'>
          {title}
        </Typography>
        {icon}
      </Stack>
      {description && (
        <Typography
          variant='text3'
          color='base.200'
          sx={{ maxWidth: '80%', '& p': { m: 0 } }}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      )}

      {buttonText && (
        <>
          <Box component='div' flexGrow={1} />
          <Button
            subject={subject}
            variant='contained'
            size='small'
            endIcon={<ArrowForward />}
            darkModeColor='dark'
            sx={{
              width: 'fit-content',
              mt: 1,
              '&:hover': {
                backgroundColor: isLightTheme ? 'base.100' : 'base.600',
                color: isLightTheme ? 'base.900' : 'base.100',
              },
            }}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </>
      )}
    </Card>
  );
};
export default PromoSubjectCard;
