import { Box } from '@mui/material';
import { useState } from 'react';
import Lightbox from 'components/modals/Lightbox';

interface IImageProps {
  questionImg: string;
}

const Image = ({ questionImg }: IImageProps) => {
  const [openLightbox, setOpenLightBox] = useState(false);
  const handleShowLightbox = () => {
    setOpenLightBox((prevState) => !prevState);
  };

  return (
    <>
      <Box
        component='div'
        onClick={handleShowLightbox}
        sx={{
          border: '2px solid',
          borderColor: 'base.300',
          borderRadius: '10px',
          mb: 2,
          '& > img': {
            height: 'auto',
            maxHeight: 320,
            display: 'block',
            mx: 'auto',
            maxWidth: '100%',
          },
        }}
      >
        <img src={questionImg} alt='question' />
      </Box>
      <Lightbox open={openLightbox} onClose={handleShowLightbox} image={questionImg} />
    </>
  );
};

export default Image;
