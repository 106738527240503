import { Box, Card, Typography, Button } from '@mui/material';
import { BackButton } from 'components/index';
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { FC, useEffect } from 'react';
import { QuestionType, useGetHomeworkResultQuery } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import { ArrowForward } from '@mui/icons-material';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import IMAGES from 'constants/images';

type HomeworkInfoParams = { courseId: string; exerciseId: string; blockId: string; lessonId: string };

const HomeworkInfo: FC = () => {
  const navigate = useNavigate();
  const { subject, lesson, refetchLesson } = useOutletContext<IExerciseOutletContext>();
  const params = useParams<HomeworkInfoParams>();

  const { data, loading } = useGetHomeworkResultQuery({
    variables: {
      exerciseId: params.exerciseId!,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    refetchLesson();
  }, []);

  const elements = data?.exercise?.elements;

  const userStatistic = data?.exercise.userStatistic;

  const hasAResult = userStatistic?.scoreA !== null;

  const scoreA: number = userStatistic?.scoreA || 0;
  const scoreB: number = userStatistic?.scoreB || 0;
  const maxScoreA: number = userStatistic?.maxScoreA || 0;
  const maxScoreB: number = userStatistic?.maxScoreB || 0;

  const doneTaskScore: number = scoreA + scoreB;
  const maxScore: number = maxScoreA + maxScoreB;

  const isComplete = data?.exercise?.completeness?.isComplete;
  const hasFileAnswerQuestion = lesson?.hasFileAnswerQuestion;

  // @ts-ignore
  const fileAnswerElement = hasFileAnswerQuestion ? elements?.find((element) => element.questionType === QuestionType.FileAnswer) : undefined;

  if (loading) return <CircularLoading />;
  return (
    <Card>
      <BackButton navigate={() => navigate(generatePath(`/${ROUTES.BLOCK}`, params))} text='Назад к урокам' />
      <Typography variant='h2' sx={{ mb: 3.5, mt: 2.5 }}>
        Ура! Вы сделали дз
      </Typography>
      <Box component='img' src={IMAGES[subject].approve} alt='success' sx={{ display: 'block', mb: 2, height: 124, width: 124, mx: 'auto' }} />

      {isComplete && (
        <Typography
          variant='h3'
          sx={{
            textAlign: 'center',
            mb: 3.5,
          }}
        >
          {`${doneTaskScore}/${maxScore}`}
        </Typography>
      )}
      {hasAResult && (
        <Button color='primary' variant='contained' subject={subject} fullWidth onClick={() => navigate(generatePath(`/${ROUTES.EXERCISE}/result`, params))}>
          Посмотреть баллы 1 части
        </Button>
      )}
      {hasFileAnswerQuestion && (
        <Typography
          variant='text4'
          sx={{
            textAlign: 'center',
            mt: 2.5,
          }}
        >
          {isComplete
            ? 'Куратор проверил 2 часть домашнего задания и выставил оценку'
            : 'Скоро работу проверит куратор и выставит оценку 2 части домашнего задания'}
        </Typography>
      )}
      {hasFileAnswerQuestion && isComplete && (
        <Button
          variant='contained'
          fullWidth
          sx={{ mt: 1 }}
          subject={subject}
          endIcon={<ArrowForward />}
          onClick={() => navigate(`/${generatePath(ROUTES.ELEMENT, { ...params, elementId: fileAnswerElement?.id! })}`)}
        >
          Просмотреть баллы 2 части
        </Button>
      )}
    </Card>
  );
};

export default HomeworkInfo;
