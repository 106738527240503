import { IRadioButtonProps } from 'components/form/RadioButtonControl';

const generateList = (item: IRadioButtonProps | string, index: number) => {
  let value;
  let label;
  if (typeof item === 'string') {
    value = index + 1;
    label = item;
  } else {
    value = item.value;
    label = item.label;
  }

  return { value, label };
};

export default generateList;
