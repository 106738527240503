import { useParams } from 'react-router-dom';
import React from 'react';
import { ExerciseQuery, ExerciseType, useExerciseQuery } from '@generated/graphql';
import Lecture from 'pages/Lesson/Lecture/Lecture';
import Homework from 'pages/Lesson/Homework/Homework';
import CircularLoading from 'components/CircularLoading';
import { Typography } from '@mui/material';

export type ExerciseQueryType = ExerciseQuery['exercise'] | undefined;
export type ExercisePageParams = { exerciseId: string; courseId: string; elementId?: string; blockId: string; lessonId: string };

const ExercisePage = () => {
  const params = useParams<ExercisePageParams>();

  const { data, loading } = useExerciseQuery({
    variables: {
      exerciseId: params.exerciseId!,
    },
  });

  const exercise: ExerciseQueryType = data?.exercise;

  const exerciseType = exercise?.type;
  const isLecture = exerciseType === ExerciseType.Lecture;
  if (loading) return <CircularLoading />;

  if (!loading && !exercise) return <Typography>Урок не найден</Typography>;
  if (isLecture) return <Lecture exercise={exercise!} />;
  return <Homework exercise={exercise!} />;
};

export default ExercisePage;
