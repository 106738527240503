import { useAvailableCourseCardsQuery } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import { useEffect } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';

const RedirectPage = () => {
  const navigate = useNavigate();
  const { data, loading } = useAvailableCourseCardsQuery();

  useEffect(() => {
    if (!loading) {
      if (data?.availableCourseCards?.length) {
        const firstCourseId = data.availableCourseCards[0].course.id;
        navigate(`/${generatePath(ROUTES.COURSE, { courseId: firstCourseId })}`);
      } else {
        navigate(`/${ROUTES.ONBOARDING}`);
      }
    }
  }, [data, loading]);

  if (loading) return <CircularLoading />;
  return <div />;
};

export default RedirectPage;
