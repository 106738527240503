import { Chip, Stack } from '@mui/material';
import SUBJECT from 'constants/subject';
import { FC, useContext } from 'react';
import { AuthContext } from 'context/authContext';
import { CourseSubject } from '@generated/graphql';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { useUsername } from 'helpers/main';

const CourseHeader: FC<{ subject: CourseSubject | undefined }> = ({ subject }) => {
  const navigate = useNavigate();
  const { me } = useContext(AuthContext);
  const username = useUsername(me);

  const navigateToUserProfile = () => navigate(`/${ROUTES.USER_PROFILE}`);
  const navigateToUserCourses = () => navigate(`/${ROUTES.USER_COURSES}`);

  return (
    <Stack direction='row' justifyContent='space-between'>
      {subject && <Chip label={SUBJECT[subject].name} onClick={navigateToUserCourses} />}
      {username && <Chip label={username} onClick={navigateToUserProfile} />}
    </Stack>
  );
};

export default CourseHeader;
