import React, { useContext, useEffect, useRef } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLoginByTelegramMutation } from '@generated/graphql';
import cookies from 'js-cookie';
import { generateBase64 } from 'helpers/formaters';
import { AuthContext } from 'context/authContext';

const BOT_NAME = process.env.REACT_APP_BOT_NAME as string;
const TelegramSignUp = () => {
  const navigate = useNavigate();
  const telegramWrapperRef = useRef<HTMLDivElement>(null);
  const { me } = useContext(AuthContext);

  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://telegram.org/js/telegram-widget.js?22';
    scriptElement.setAttribute('data-telegram-login', BOT_NAME);
    scriptElement.setAttribute('data-size', 'large');
    scriptElement.setAttribute('data-auth-url', window.location.href);
    scriptElement.setAttribute('data-request-access', 'write');

    scriptElement.async = true;

    // @ts-ignore
    telegramWrapperRef.current.appendChild(scriptElement);
  }, []);

  const [searchParams] = useSearchParams();
  const searchObject = Object.fromEntries(searchParams);
  const { redirectUrl, isOnboarding, ...telegramAuth } = searchObject;

  const token = searchParams.has('hash') ? generateBase64(JSON.stringify(telegramAuth)) : undefined;
  const [loginByTelegram] = useLoginByTelegramMutation();

  useEffect(() => {
    if (token) {
      loginByTelegram({
        variables: {
          token,
        },
      }).then((res) => {
        const accessToken = res.data?.loginByTelegram.accessToken!;
        const refreshToken = res.data?.loginByTelegram.refreshToken!;

        const newCookies: Array<{ name: string; value: string }> = [
          {
            name: 'accessToken',
            value: accessToken,
          },
          {
            name: 'refreshToken',
            value: refreshToken,
          },
        ];

        newCookies.forEach((cookie) => cookies.set(cookie.name, cookie.value));
        if (isOnboarding) navigate('/onboarding');
        else if (redirectUrl) {
          document.location.href = redirectUrl;
          // eslint-disable-next-line no-restricted-globals
        } else document.location.href = `${location.origin}`;
      });
    } else if (me) navigate('/onboarding');
  }, [token, me]);

  return (
    <Card>
      <Typography variant='h2' sx={{ mb: 0.5 }}>
        Войди через Телеграм
      </Typography>
      <Typography variant='text7'>Чтобы мы могли сохранить твой аккаунт и держать тебя в курсе новостей</Typography>
      <Box component='div' sx={{ mt: 2.5, mx: 'auto', width: 'fit-content' }} ref={telegramWrapperRef} />
    </Card>
  );
};

export default TelegramSignUp;
