import { Form } from 'components/form/Form';
import { Header } from 'components/ElementsGenerator';
import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { Card, IconButton, Stack } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import { BackButton } from 'components/index';
import ROUTES from 'constants/routes';
import ElementsGenerator from 'components/ElementsGenerator/ElementsGenerator';
import { ExerciseQuery, QuestionType, useCreateUserAnswerMutation } from '@generated/graphql';
import qs from 'qs';
import { TestState } from 'pages/Lesson';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import SecondPartAnswer from 'pages/Lesson/Homework/SecondPartAnswer/SecondPartAnswer';
import { LoadingButton } from '@mui/lab';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import useCurrentElement from 'pages/Lesson/Homework/hooks';
import { checkAnswerIsReady, initializeForm, prepareAnswerInput } from './utils/utils';
import { ElementsArray, HomeworkParams, isFileElementLkDto, isQuestionElement } from './types';

const Homework: FC<{ exercise: ExerciseQuery['exercise'] }> = ({ exercise }) => {
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();

  const params = useParams<HomeworkParams>();

  const { subject, lesson } = useOutletContext<IExerciseOutletContext>();
  const [searchParams] = useSearchParams();
  const state = searchParams.get('state');

  const elements: ElementsArray = exercise?.elements || [];

  const {
    elementsList,
    currentElement,
    currentElementOrder,
    isProgressDone,
    isLastQuestion,
    isFirstQuestion,
    totalTaskCount,
    elementType,
    isExerciseComplete,
  } = useCurrentElement(elements, exercise);

  const isQuestion = currentElement && isQuestionElement(currentElement);
  const isFileElement = currentElement && isFileElementLkDto(currentElement);

  const form = useForm({
    values: initializeForm(elements),
  });
  const { handleSubmit } = form;

  const [saveUserAnswer, { loading: saveLoading }] = useCreateUserAnswerMutation();

  const handleChangeTask = (direction?: 'next') => {
    const isForward = direction === 'next';
    const newTaskNumber = isForward ? currentElementOrder + 1 : currentElementOrder - 1;
    if (isLastQuestion && isForward) {
      navigate({
        pathname: generatePath(`/${ROUTES.EXERCISE}/${isProgressDone ? 'result' : 'preview'}`, params),
      });
    } else {
      navigate({
        pathname: generatePath(`/${ROUTES.ELEMENT}`, {
          ...params,
          elementId: elementsList![newTaskNumber - 1].id,
        }),
        search: qs.stringify(Object.fromEntries(searchParams.entries())),
      });
    }
  };

  const currentElementName: any = `answer-${currentElement?.id}`;

  const onSubmit = (formData: any) => {
    const answer = formData[currentElementName];

    const isAnswerReady = checkAnswerIsReady(answer, currentElement);

    if (!isAnswerReady || !isQuestion || isProgressDone || !answer) {
      handleChangeTask('next');
    } else {
      saveUserAnswer({
        variables: {
          input: prepareAnswerInput(currentElement, answer),
        },
      })
        .then(() => handleChangeTask('next'))
        .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
    }
  };

  if (lesson.hasFileAnswerQuestion && isQuestion && currentElement.questionType === QuestionType.FileAnswer && isExerciseComplete) {
    return <SecondPartAnswer currentElement={currentElement} userStatistic={exercise.userStatistic} />;
  }
  return (
    <>
      <Card sx={{ mb: 2.5 }} component={Stack} spacing={2.5}>
        <BackButton
          navigate={() =>
            state === TestState.previewAnswer || state === TestState.resultAnswer
              ? navigate({
                  pathname: generatePath(`/${ROUTES.EXERCISE}/${state.includes('result') ? TestState.result : TestState.preview}`, params),
                })
              : navigate({ pathname: generatePath(`/${ROUTES.BLOCK}`, params) })
          }
          text={state === TestState.previewAnswer || state === TestState.resultAnswer ? 'Назад к результатам' : 'Назад к урокам'}
        />
        <Header title={exercise?.title} />
      </Card>
      <Form form={form}>
        <ElementsGenerator
          type={elementType!}
          item={{
            ...currentElement,
            placeholder: isQuestion ? currentElement?.placeholder || 'Введите ответ' : undefined,
            // @ts-ignore TODO
            matchingList: isQuestion ? currentElement?.matchingAnswers?.map((match: string) => ({ label: match, value: match })) : undefined,
            isError: isQuestion ? currentElement?.currentUserProgress?.isCorrect === false : undefined,
            subject,
            name: currentElementName,
            currentTask: currentElementOrder,
            totalTaskCount,
            form,
            disabled: exercise.userStatistic?.scoreA !== null,
            questionImg: isQuestion || isFileElement ? currentElement?.files?.[0]?.file?.url : undefined,
          }}
        />
      </Form>
      {!!elementsList?.length && (
        <Stack
          sx={{
            mt: 2,
            flex: 1,
          }}
          direction='row'
          spacing={1}
          alignItems='flex-end'
        >
          {!isFirstQuestion && (
            <IconButton variant='contained' onClick={() => handleChangeTask()} size='large'>
              <KeyboardArrowLeft />
            </IconButton>
          )}

          <LoadingButton loading={saveLoading} variant='contained' fullWidth color='secondary' subject={subject} onClick={handleSubmit(onSubmit)}>
            {isLastQuestion ? 'Завершить' : 'Следующее задание'}
          </LoadingButton>
        </Stack>
      )}
    </>
  );
};

export default Homework;
