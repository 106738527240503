import React, { FC, useState } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card, Stack, Typography, useTheme } from '@mui/material';
import useThemeMode from 'hooks/useThemeMode';
import { ScoreType } from '@generated/graphql';
import { getChartData, getChartOptions } from './config';
import ChartPagination from './ChartPagination';
import getColor from './utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const HomeworkChart: FC<{ chartData: ScoreType[] }> = ({ chartData }) => {
  const { palette } = useTheme();
  const { isLightTheme } = useThemeMode();

  const [firstColumnNumber, setFirstColumnNumber] = useState<number>(0);

  const initialScores = chartData.map((d, index) => ({
    ...d,
    y: d.maxScore !== 0 ? Math.floor(((d.score ?? 0) / d.maxScore) * 100) : 0,
    label: index + 1,
    backgroundColor: getColor(d.score, d.maxScore, palette),
    color: d.score === null ? palette.base['300'] : palette.baseShift[100],
  }));

  const [scores, setScores] = useState(initialScores.slice(0, 5));

  const data = getChartData(scores);
  const options = getChartOptions(scores, palette);

  return (
    <Card sx={{ bgcolor: 'baseShift.500', boxShadow: isLightTheme ? 1 : 2 }}>
      <Stack direction='row' justifyContent='space-between' spacing={2} mb={2}>
        <Typography variant='h2' sx={{ color: 'baseShift.text', mb: 3 }}>
          Домашние задания
        </Typography>
        {!(firstColumnNumber === 0 && initialScores.length <= firstColumnNumber + 5) && (
          <ChartPagination {...{ firstColumnNumber, setFirstColumnNumber, setScores, initialScores }} />
        )}
      </Stack>
      {/* @ts-ignore */}
      <Bar options={options} data={data} />
    </Card>
  );
};

export default HomeworkChart;
