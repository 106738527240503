/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/chill_lvl_0_emb.gltf
*/

/*
eslint-disable react/no-unknown-property
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import startAnimation from '../helpers/startAnimation';

const ChillModel = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/chill_lvl_0_emb.gltf');
  const { actions, names } = useAnimations(animations, group);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    startAnimation(actions, names);
  }, [actions, names]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <group name='ARMA_chill_lvl_0' position={[0, 0, 0.5]}>
          <primitive object={nodes.pivot} />
          <skinnedMesh
            castShadow
            name='MESH_chill_lvl_0'
            geometry={nodes.MESH_chill_lvl_0.geometry}
            material={materials.Texture_capibara}
            skeleton={nodes.MESH_chill_lvl_0.skeleton}
          />
        </group>
        <group name='ARMA_Z_1' position={[-0.345, 1.315, -1.929]}>
          <primitive object={nodes.Bone} />
        </group>
        <group name='ARMA_Z_2' position={[-0.345, 1.315, -1.929]}>
          <primitive object={nodes.Bone_1} />
        </group>
        <group name='ARMA_Z_3' position={[-0.345, 1.315, -1.929]}>
          <primitive object={nodes.Bone_2} />
        </group>
      </group>
    </group>
  );
};

export default ChillModel;

useGLTF.preload('/chill_lvl_0_emb.gltf');
