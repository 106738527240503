import { Stack, SxProps, Typography } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface IInfoBannerProps {
  text?: string | null;
  containerSx?: SxProps;
  link?: string;
  onClick?: () => void;
  icon: ReactElement;
}

const InfoBanner: FC<IInfoBannerProps> = ({ text, containerSx, icon, link, onClick }) => (
  <Stack
    component={link ? Link : 'div'}
    to={link}
    flexDirection='row'
    justifyContent='space-between'
    alignItems='center'
    onClick={onClick}
    sx={{
      bgcolor: 'baseShift.400',
      borderRadius: 2,
      padding: 2,
      cursor: 'pointer',
      ...containerSx,
    }}
  >
    <Typography variant='text3' sx={{ whiteSpace: 'pre-wrap', mr: 1 }}>
      {text}
    </Typography>
    {icon}
  </Stack>
);

export default InfoBanner;

InfoBanner.defaultProps = {
  containerSx: {},
  text: undefined,
  onClick: undefined,
  link: undefined,
};
