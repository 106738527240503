import { useTheme } from '@mui/material';

const useThemeMode = () => {
  const {
    palette: { mode },
  } = useTheme();
  const isLightTheme = mode === 'light';
  return { isLightTheme, mode };
};

export default useThemeMode;
