import { Box, Card, Chip, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { CourseSubject } from '@generated/graphql';
import Scene from 'pages/Course/components/Scene';
import { formatNumber } from 'helpers/formaters';
import { CAPYBARA_NAME, CapybaraType } from 'constants/capybara';
import CourseColorButton from 'components/buttons/CourseColorButton';
import { useNavigate } from 'react-router-dom';

type CharacterProps = {
  subject: CourseSubject | undefined;
  courseProgress: number;
  customCapybaraType?: CapybaraType;
};

const Character: FC<CharacterProps> = ({ subject, courseProgress, customCapybaraType }) => {
  const navigate = useNavigate();

  const selectCapybaraState = (): CapybaraType => {
    if (courseProgress > 0 && courseProgress <= 10) return CapybaraType.Baby1;
    if (courseProgress > 10 && courseProgress <= 20) return CapybaraType.Beginner1;
    if (courseProgress > 20 && courseProgress <= 30) return CapybaraType.Explorer1;
    if (courseProgress > 30 && courseProgress <= 40) return CapybaraType.BStudent1;
    if (courseProgress > 40 && courseProgress <= 50) return CapybaraType.AStudent1;
    if (courseProgress > 50 && courseProgress <= 60) return CapybaraType.Student;
    if (courseProgress > 60 && courseProgress <= 70) return CapybaraType.Expert1;
    if (courseProgress > 70 && courseProgress <= 80) return CapybaraType.Applicant;
    if (courseProgress > 80 && courseProgress <= 100) return CapybaraType.Legend;
    return CapybaraType.Chill;
  };

  const capybaraType = customCapybaraType || selectCapybaraState();
  return (
    <Card
      sx={(theme) => ({
        height: 400,
        background: subject
          ? `radial-gradient(50% 50% at 50% 50%, 
            ${theme.palette[subject].main} 0%, ${theme.palette.mode === 'light' ? '#fff' : '#152746'} 100%)`
          : undefined,
        position: 'relative',
        p: 0,
      })}
    >
      <Box component='div' sx={{ zIndex: 100, px: 2, pt: 2, position: 'absolute' }}>
        <Typography
          variant='h4'
          sx={{
            color: 'baseShift.100',
            mb: 1,
          }}
        >
          Твой рейтинг —
          <br />
          это твоя капибара
        </Typography>
        <Stack
          direction='row'
          spacing={1}
          sx={{
            position: 'absolute',
            top: 80,
            zIndex: 100,
          }}
        >
          <Chip label={CAPYBARA_NAME[capybaraType]} size='small' />
          <Chip label={formatNumber(courseProgress, ['балл', 'балла', 'баллов'])} size='small' />
        </Stack>
      </Box>

      <Box
        component='div'
        sx={{
          height: '85%',
          zIndex: 0,
        }}
      >
        <Scene capybaraType={capybaraType} />
      </Box>

      <Box component='div' sx={{ zIndex: 100, px: 2, pb: 2 }}>
        <CourseColorButton subject={subject} fullWidth variant='contained' onClick={() => navigate('dashboard')}>
          Подробнее
        </CourseColorButton>
      </Box>
    </Card>
  );
};

export default Character;

Character.defaultProps = {
  customCapybaraType: undefined,
};
