import { styled } from '@mui/material/styles';
import MuiContainer from '@mui/material/Container';

const Container = styled(MuiContainer)(() => ({
  minHeight: '100vh',
  paddingRight: 20,
  paddingLeft: 20,
  paddingTop: 16,
  paddingBottom: 16,
  display: 'flex',
  flexDirection: 'column',
}));

export default Container;
