import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { CourseSubject } from '@generated/graphql';

export enum ModalActionEnum {
  close = 'close',
}

export interface IModalButton {
  text: string;
  action?: (() => any) | ModalActionEnum.close;
}

export interface ModalProps {
  open: boolean;
  title: string;
  bodyText?: string;
  img?: string;
  onClose: () => void;
  mainButton?: IModalButton;
  actionButton?: IModalButton;
  loading?: boolean;
  content?: any;
  subject: CourseSubject;
}

const Modal: FC<ModalProps> = ({ open, title, bodyText, img, onClose, actionButton, mainButton, loading, content, subject }) => {
  if (!open) return null;
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant='h3' textAlign='center'>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack alignItems='center'>
          {img && <img src={img} alt='modal' />}
          {bodyText && <Typography variant='text2'>{bodyText}</Typography>}
          {content}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack sx={{ width: '100%' }} spacing={1.3}>
          {actionButton && (
            <LoadingButton
              variant='contained'
              color='secondary'
              size='small'
              fullWidth
              loading={loading}
              onClick={() => (typeof actionButton.action === 'string' ? onClose() : actionButton?.action?.())}
              subject={subject}
            >
              {actionButton.text}
            </LoadingButton>
          )}
          {mainButton && (
            <LoadingButton
              variant='contained'
              color='primary'
              size='small'
              fullWidth
              loading={loading}
              onClick={() => (typeof mainButton.action === 'string' ? onClose() : mainButton?.action?.())}
              subject={subject}
            >
              {mainButton.text}
            </LoadingButton>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;

Modal.defaultProps = {
  img: undefined,
  actionButton: undefined,
  loading: false,
  bodyText: undefined,
  content: undefined,
  mainButton: undefined,
};
