import { ElementType, ExerciseQuery, FileElementLkDto, QuestionElementLkDto } from '@generated/graphql';

export type HomeworkParams = { exerciseId: string; blockId: string; lessonId: string; elementId: string };
export type ElementsArray = ExerciseQuery['exercise']['elements'];
export type ExerciseElement = NonNullable<ExerciseQuery['exercise']['elements']>[number];

export function isQuestionElement(element: ExerciseElement): element is QuestionElementLkDto {
  // eslint-disable-next-line no-underscore-dangle
  return element.__typename === 'QuestionElementLkDto' || element.type === ElementType.Question;
}

export function isFileElementLkDto(element: ExerciseElement): element is FileElementLkDto {
  // eslint-disable-next-line no-underscore-dangle
  return element.__typename === 'FileElementLkDto';
}

export type TextAnswer = { text: string; isCorrect: boolean };
export type FewAnswers = { [key: string]: { checked: boolean; isCorrect: boolean } };
export type MatchingAnswerItem = { text: string; elementPartId: string; isCorrect: boolean | undefined };
export type MatchingAnswer = Array<{ text: string; elementPartId: string; isCorrect: boolean | undefined } | undefined>;
export type SingleAnswer = { checked: string; isCorrect: boolean };
export type FileAnswer = Array<File>;

export type Answer = TextAnswer | FewAnswers | MatchingAnswer | SingleAnswer | FileAnswer;
