import { CourseSubject } from '@generated/graphql';

const generateMainBackgroundGradient = (color: string) => `linear-gradient(177deg, #F8FAFD 2.56%, #F2F4F7 38.96%, ${color} 53.07%, #F2F4F7 95.33%)`;

const generateBackgroundGradient = (color: string) => `linear-gradient(177deg, #F8FAFD 2.56%, #F8FAFD 38.96%, #F2F4F7 76.46%, ${color} 103.19%)`;

const colors = {
  error: {
    light: '#E5AAAA',
    main: '#FF6C6C',
  },
  assessment: {
    light: {
      fault: 'rgba(255, 108, 108)',
      excellent: 'rgba(91, 255, 117)',
      good: 'rgba(255, 246, 37, 0.70)',
    },
    dark: {
      fault: 'rgba(255, 108, 108, 0.40)',
      excellent: 'rgba(91, 255, 117, 0.59)',
      good: 'rgba(245, 223, 28, 0.90)',
    },
  },
  [CourseSubject.Biology]: {
    main: '#2FC373',
    mainBackground: generateMainBackgroundGradient('#C2FFE0'),
    background: generateBackgroundGradient('#DBFFED'),
    cover: 'linear-gradient(90deg, #2FC373 0%, #4E72BD 100%)',
    gradient2: 'linear-gradient(90deg, #2FC373 0%, #3C90F3 99.37%)',
  },
  [CourseSubject.Chemistry]: {
    main: '#AC80E4',
    mainBackground: generateMainBackgroundGradient('#ECD8FF'),
    background: generateBackgroundGradient('#F4E8FF'),
    cover: 'linear-gradient(90deg, #9649E1 0%, #4E72BD 100%)',
    gradient2: 'linear-gradient(90deg, #B05EFF 0%, #3C93EE 100%)',
  },
  [CourseSubject.Math]: {
    main: '#FF8C36',
    mainBackground: generateMainBackgroundGradient('#FFDEC6'),
    background: generateBackgroundGradient('#FFDEC6'),
    cover: 'linear-gradient(90deg, #FF8C36 0%, #FFCA2A 100%)',
    gradient2: 'linear-gradient(90deg, #FF8C36 0%, #FAE100 100%)',
  },
  [CourseSubject.Physics]: {
    main: '#755CE6',
    mainBackground: generateMainBackgroundGradient('#E7E2FF'),
    background: generateBackgroundGradient('#E7E2FF'),
    cover: 'linear-gradient(90deg, #8A70FF 11.2%, #389AFF 100%)',
    gradient2: 'linear-gradient(90deg, #8A70FF 0%, #67DBF9 100%)',
  },
  [CourseSubject.History]: {
    main: '#0E91F0',
    mainBackground: generateMainBackgroundGradient('#D5ECFD'),
    background: generateBackgroundGradient('#D5ECFD'),
    cover: 'linear-gradient(90deg, #0E91F0 0%, #8E74E1 100%)',
    gradient2: 'linear-gradient(90deg, #0E91F0 0%, #A993FF 100%)',
  },
  [CourseSubject.RussianLanguage]: {
    main: '#FF5775',
    mainBackground: generateMainBackgroundGradient('#FFE0E6'),
    background: generateBackgroundGradient('#FFE0E6'),
    cover: 'linear-gradient(90deg, #FF5789 9.8%, #586DC2 100%)',
    gradient2: 'linear-gradient(90deg, #FF5789 0%, #C06AB3 40.58%, #458FF0 100%)',
  },
  [CourseSubject.Informatics]: {
    main: '#3DD9BD',
    mainBackground: generateMainBackgroundGradient('#D2FFF7'),
    background: generateBackgroundGradient('#D2FFF7'),
    cover: 'linear-gradient(90deg, #3DD9BD 0%, #5E79EA 100%)',
    gradient2: 'linear-gradient(90deg, #3DD9BD 0%, #458FF0 100%)',
  },
  [CourseSubject.SocialScience]: {
    main: '#FB4BCA',
    mainBackground: generateMainBackgroundGradient('#FFE1F7'),
    background: generateBackgroundGradient('#F2F4F7'),
    cover: 'linear-gradient(90deg, #FB4BCA 0%, #586DC2 100%)',
    gradient2: 'linear-gradient(90deg, #FB4BCA 0%, #458FF0 100%)',
  },
  [CourseSubject.Literature]: {
    main: '#FFCB31',
    mainBackground: generateMainBackgroundGradient('#FFF9BF'),
    background: generateBackgroundGradient('#FFF9BF'),
    cover: 'linear-gradient(90deg, #FDBD00 10.67%, #D1C612 55.67%, #84D325 100%)',
    gradient2: 'linear-gradient(90deg, #FFCA2A 0%, #94DA3E 100%)',
  },
  defaultColor: {
    main: '#4B7DFF',
    mainBackground: generateMainBackgroundGradient('#D5E0FD'),
    background: generateBackgroundGradient('#D5E0FD'),
    cover: '#F8FAFD',
    gradient2: '#F8FAFD',
  },
  base: {
    100: '#FFFFFF',
    200: '#E8ECEF',
    300: '#B9CCD6',
    400: '#ACB0B4',
    500: '#798AA8',
    600: '#626E80',
    700: '#283750',
    750: 'rgba(47, 83, 147, 0.7)',
    800: '#01163F',
    900: '#161D27',
  },

  blue: {
    main: '#4B7DFF',
  },
};

export default colors;
