import React from 'react';
import { OnboardingCourseCardsQuery } from '@generated/graphql';
import { useOutletContext } from 'react-router-dom';
import { OnboardingOutletProps } from 'pages/Onboarding/OnboardingOutlet';
import { Card, Stack, Typography } from '@mui/material';
import CourseCardButton from 'pages/Onboarding/components/CourseCard/CourseCardButton';
import CourseCardCheckbox from 'pages/Onboarding/components/CourseCard/CourseCardCheckbox';

interface CourseCardProps {
  card: OnboardingCourseCardsQuery['onboardingCourseCards'][number];
}

const Index = ({ card }: CourseCardProps) => {
  const { subject, title, grade, id } = card.course;

  const { selectedCoursesByGrade, setSelectedCoursesByGrade } = useOutletContext<OnboardingOutletProps>();
  const selectedCourses = selectedCoursesByGrade[grade] || [];

  const isSelected = selectedCourses.includes(id);

  const toggleCourseSelection = () => {
    setSelectedCoursesByGrade((prevState) => {
      const currentCourses = prevState[grade] || [];
      const isIdAlreadyExist = currentCourses.includes(id);
      return {
        ...prevState,
        [grade]: isIdAlreadyExist ? currentCourses.filter((courseId) => courseId !== id) : [...currentCourses, id],
      };
    });
  };

  return (
    <Card
      component={Stack}
      onClick={toggleCourseSelection}
      sx={{
        cursor: 'pointer',
        backgroundColor: isSelected ? `${subject}.main` : 'transparent',
        minHeight: 'auto',
        '-webkit-tap-highlight-color': 'transparent',
      }}
    >
      <Stack direction='row' justifyContent='space-between'>
        <Typography
          sx={{
            color: isSelected ? 'white' : `${subject}.main`,
          }}
          variant='h3'
        >
          {title}
        </Typography>
        <CourseCardCheckbox isSelected={isSelected} />
      </Stack>
      <CourseCardButton course={card.course} isSelected={isSelected} />
    </Card>
  );
};

export default Index;
