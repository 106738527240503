export default {
  mode: 'dark',
  baseShift: {
    text: '#FFF',
    100: '#FFF',
    200: '#ACB0B4',
    300: '#798AA8',
    400: '#626E80',
    500: '#283750',
  },
  background: {
    default: '#161D27',
  },
};
