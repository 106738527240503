import { Palette } from '@mui/material';

export const getChartData = (scores: any) => ({
  labels: scores.map((v: { label: any }) => v.label),
  datasets: [
    {
      data: scores.map((v: { y: any }) => v.y),
      backgroundColor: scores.map((v: { backgroundColor: any }) => v.backgroundColor),
      minBarLength: 30,
      fill: true,
      borderRadius: 10,
      borderSkipped: false,
      maxBarThickness: 60,
      barPercentage: 1,
    },
  ],
});

export const getChartOptions = (scores: any, palette: Palette) => ({
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
    datalabels: {
      display: true,
      anchor: 'end',
      clamp: true,
      align: 'start',
      color: (ctx: any) => scores[ctx.dataIndex].color,
      font: {
        size: 16,
        weight: 500,
        family: 'Inter',
      },
      formatter(value: any) {
        return `${value}%`;
      },
    },
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: 16,
          weight: 500,
          family: 'Inter',
        },
        color: palette.baseShift[100],
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
    y: {
      max: 100,
      ticks: {
        display: false,
        beginAtZero: true,
      },
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
});
