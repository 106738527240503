import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Card, Collapse, LinearProgress, Stack, Typography } from '@mui/material';
import { BackButton } from 'components';
import LessonCard from 'pages/Block/components/LessonCard';
import { useState } from 'react';
import ROUTES from 'constants/routes';
import { BlockQuery, ProgressStatistic, useBlockQuery } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import HomeworkChart from 'components/charts/HomeworkChart';
import { IOutletContext } from 'types/common';
import LectureProgressCard from 'components/charts/LectureProgressCard';
import ExpandMoreButton from 'components/buttons/ExpandMoreButton';
import CourseColorButton from 'components/buttons/CourseColorButton';
import useBlockData, { checkIsUserCanBuyBlock } from 'hooks/useBlockData';

type Block = BlockQuery['block'] | undefined;
type LessonsType = BlockQuery['block']['lessons'] | undefined;
export type LessonType = NonNullable<BlockQuery['block']['lessons']>[number];

type BlockPageParams = { blockId: string; courseId: string };

const BlockPage = () => {
  const { subject } = useOutletContext<IOutletContext>();
  const navigate = useNavigate();
  const params = useParams<BlockPageParams>();
  const courseId = params.courseId as string;
  const blockId = params.blockId as string;

  const [openStatistic, setOpenStatistic] = useState<boolean>(false);
  const { data, loading, error } = useBlockQuery({
    variables: {
      blockId,
    },
    fetchPolicy: 'network-only',
  });

  const block: Block = data?.block;
  const lessons: LessonsType = block?.lessons;
  const blockStatistic = block?.statistic as ProgressStatistic;

  const blockStatisticsProgress = Math.round((blockStatistic?.progress || 0) * 100);

  const { isUserHasBlock, onNavigateToPurchaseBlock } = useBlockData({ block });
  const isUserCanBuyBlock = checkIsUserCanBuyBlock(block?.unavailableReason);

  if (loading) return <CircularLoading />;
  if (error?.message) return <Typography>{error?.message}</Typography>;
  return (
    <>
      <Card sx={{ mb: 2.5 }}>
        <BackButton text='На главную' navigate={() => navigate(generatePath(`/${ROUTES.COURSE}`, { courseId }))} />
        {blockStatistic && (
          <>
            <Stack
              spacing={1}
              direction='row'
              justifyContent='space-between'
              sx={{
                position: 'relative',
                mb: 1.5,
                mt: 2.5,
              }}
            >
              <Typography variant='h4'>{`Твой прогресс: ${blockStatisticsProgress}%`}</Typography>
              {isUserHasBlock && (
                <ExpandMoreButton
                  isOpen={openStatistic}
                  onClick={() => setOpenStatistic((prev) => !prev)}
                  sx={{
                    right: 0,
                    top: '-5px',
                  }}
                />
              )}
            </Stack>
            <Collapse in={!openStatistic}>
              <LinearProgress value={blockStatisticsProgress} coloringType='gradient' subject={subject} />
            </Collapse>
            <Collapse in={openStatistic}>
              {!!blockStatistic.lectureScore.maxScore && <LectureProgressCard blockStatistic={blockStatistic} />}

              <HomeworkChart chartData={blockStatistic.homeworkScore} />
            </Collapse>
          </>
        )}
      </Card>
      <Card sx={{ mb: 2.5 }}>
        <Typography variant='h1' sx={{ mb: 2.5 }}>
          Все уроки:
        </Typography>
        <Stack spacing={2}>{lessons?.map((lesson: LessonType, index: number) => <LessonCard {...{ lesson, index }} key={`lesson-${lesson.id}`} />)}</Stack>
      </Card>
      {isUserCanBuyBlock && (
        <CourseColorButton subject={subject} sx={{ position: 'sticky', bottom: 16, mt: 'auto' }} onClick={onNavigateToPurchaseBlock} variant='contained'>
          Купить
        </CourseColorButton>
      )}
    </>
  );
};

export default BlockPage;
