import { useParams } from 'react-router-dom';
import PromoSubjectCard from 'components/dashboard/PromoSubjectCard';
import { CourseCardType, useCourseCardByIdQuery } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import PaymentCard, { PaymentCardCourse } from 'pages/Payment/PaymentCard';
import { Card, Stack } from '@mui/material';
import Text from 'components/ElementsGenerator/elements/Text';

const CardPreview = () => {
  const { cardId } = useParams<{ cardId: string }>();
  const { data, loading } = useCourseCardByIdQuery({
    variables: {
      courseCardId: cardId!,
    },
  });

  const courseCard = data?.courseCardById;
  const course = courseCard?.course;
  const coverFile = courseCard?.useDefaultCover ? course?.coverFile : courseCard?.coverFile;

  if (loading) return <CircularLoading />;

  return (
    <>
      {(courseCard?.type === CourseCardType.Advertising || courseCard?.type === CourseCardType.Presentation) && (
        <PromoSubjectCard
          title={course?.title}
          subject={course?.subject}
          backgroundImage={coverFile?.url}
          description={courseCard?.description}
          buttonText={courseCard?.link ? 'На страницу курса' : undefined}
        />
      )}
      {courseCard?.type === CourseCardType.Purchase && (
        <PaymentCard course={course as PaymentCardCourse} description={courseCard.description} coverFile={coverFile} />
      )}
      {courseCard?.type === CourseCardType.Onboarding && (
        <Stack
          spacing={2.5}
          sx={{
            flex: 1,
          }}
        >
          <Card>
            <PromoSubjectCard title={course?.title} subject={course?.subject} backgroundImage={coverFile?.url} description={course?.description} />
          </Card>
          <Card>
            <Text content={courseCard?.description!} />
          </Card>
        </Stack>
      )}
    </>
  );
};

export default CardPreview;
