import { Box, Button, Card, IconButton, Stack, Typography } from '@mui/material';
import LectureProgressCard from 'components/charts/LectureProgressCard';
import { ProgressStatistic, ScoreType, useCardsQuery, useCourseStatisticQuery } from '@generated/graphql';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import HomeworkChart from 'components/charts/HomeworkChart';
import { useContext, useMemo } from 'react';
import { IOutletContext } from 'types/common';
// import LineChart from 'pages/Course/components/LineChart';
import { Close } from '@mui/icons-material';
import CheerCard from 'pages/CourseDashboard/components/CheerCard';
import OtherCourseCards from 'components/dashboard/OtherCourseCards';
import { ModalContext, ModalType } from 'context/modalContext';

const CourseDashboard = () => {
  const { handleOpen } = useContext(ModalContext);
  const navigate = useNavigate();
  const { subject } = useOutletContext<IOutletContext>();
  const { courseId } = useParams<{ courseId: string }>();
  const { data, loading } = useCourseStatisticQuery({
    variables: {
      courseId: courseId!,
    },
  });
  const courseStatistic = data?.courseStatistic as ProgressStatistic;
  const homeworkAverage = useMemo(() => {
    if (courseStatistic?.homeworkScore?.length) {
      const doneHomeworkCount = courseStatistic?.homeworkScore?.filter((hs) => hs.score !== null)?.length;
      if (doneHomeworkCount) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        return courseStatistic?.homeworkScore?.reduce((acc: number, current: ScoreType): number => acc + (current?.score || 0), 0) / doneHomeworkCount;
      }
    }
    return 0;
  }, [courseStatistic?.homeworkScore]);

  const { data: cardsData, loading: cardsLoading } = useCardsQuery({
    variables: {
      courseId: courseId!,
    },
  });

  const availableUserCards = cardsData?.availableCourseCards;
  const advertisingCards = cardsData?.course?.advertising?.map((i) => i.card);

  const onOpenPlaceholderModal = () => {
    handleOpen({
      subject: subject!,
      title: '',
      modalType: ModalType.Placeholder,
      bodyText: 'Функция будет доступна в следующих версиях',
      actionButton: { text: 'Связаться с нами' },
      mainButton: { text: 'Закрыть' },
    });
  };

  if (loading || cardsLoading) return <CircularLoading />;

  return (
    <Card>
      <Stack spacing={2.5}>
        <Box component='div' sx={{ position: 'relative' }}>
          <Typography variant='h4' sx={{ color: 'baseShift.text' }}>
            Твой рейтинг
          </Typography>
          <IconButton sx={{ position: 'absolute', right: 0, top: -6, color: 'baseShift.text' }} onClick={() => navigate(`/course/${courseId}`)}>
            <Close />
          </IconButton>
        </Box>

        <LectureProgressCard blockStatistic={courseStatistic} />

        <HomeworkChart chartData={courseStatistic?.homeworkScore} />
        <Card sx={({ palette }) => ({ background: palette[subject].gradient2 })}>
          <Typography variant='h2' sx={{ mb: 2.5, color: 'base.100' }}>
            Средний балл ДЗ
          </Typography>
          <Typography variant='h7' sx={{ color: 'base.100' }}>
            {`${Math.ceil(homeworkAverage)}+`}
          </Typography>
        </Card>
        {/* <Card */}
        {/*   component={Stack} */}
        {/*   sx={{ */}
        {/*     bgcolor: 'baseShift.400' */}
        {/*   }} */}
        {/* > */}
        {/*   <Stack spacing={1} direction='row' justifyContent='space-between' alignItems='center' mb={2}> */}
        {/*     <Typography sx={{ color: 'baseShift.text' }} variant='h2'> */}
        {/*       Результаты пробников */}
        {/*     </Typography> */}
        {/*     <Box */}
        {/*       component='div' */}
        {/*       sx={({ palette: { mode } }) => ({ py: 1, px: 1.5, bgcolor: 'baseShift.500', borderRadius: 8, boxShadow: mode === 'light' ? 1 : 2 })} */}
        {/*     > */}
        {/*       <Typography variant='h6' sx={{ color: 'baseShift.100' }}> */}
        {/*         5/5 */}
        {/*       </Typography> */}
        {/*     </Box> */}
        {/*   </Stack> */}
        {/*   <Box component='div' sx={{ mx: -1.5, display: 'flex', justifyContent: 'center' }}> */}
        {/*     <LineChart /> */}
        {/*   </Box> */}
        {/* </Card> */}
        {/* <Stack direction='row' spacing={1} justifyContent='space-between'> */}
        {/*   <Card component={Stack} justifyContent='space-between' sx={{ height: 155, width: '50%', minWidth: 15, boxShadow: isLightTheme ? 1 : 4 }}> */}
        {/*     <Typography variant='text4' sx={{ color: isLightTheme ? 'base.800' : 'base.100' }}> */}
        {/*       Прогресс результатов */}
        {/*     </Typography> */}
        {/* eslint-disable-next-line max-len */}
        {/*     <Typography variant='h8' sx={({ palette }) => ({ background: palette[subject].gradient, backgroundClip: 'text', textFillColor: 'transparent' })}> */}
        {/*       51&rarr;98 */}
        {/*     </Typography> */}
        {/*   </Card> */}
        {/*   <Card */}
        {/*     sx={({ palette }) => ({ background: palette[subject].gradient2, height: 155, width: '50%' })} */}
        {/*     component={Stack} */}
        {/*     justifyContent='space-between' */}
        {/*   > */}
        {/*     <Typography variant='text4' sx={{ color: 'base.100' }}> */}
        {/*       Мои пробники */}
        {/*     </Typography> */}
        {/*     <Typography variant='h7' sx={{ color: 'base.100' }}> */}
        {/*       59+ */}
        {/*     </Typography> */}
        {/*   </Card> */}
        {/* </Stack> */}
        {/* <Card */}
        {/*   sx={{ */}
        {/*     borderRadius: 2, */}
        {/*     bgcolor: 'baseShift.400', */}
        {/*     backgroundImage: `url(${oneMoreCourseImage})`, */}
        {/*     backgroundRepeat: 'no-repeat', */}
        {/*     backgroundSize: 'contain', */}
        {/*     backgroundPositionX: 'right', */}
        {/*     display: 'flex', */}
        {/*     alignItems: 'center', */}
        {/*     cursor: 'pointer' */}
        {/*   }} */}
        {/*   onClick={onOpenPlaceholderModal} */}
        {/* > */}
        {/*   <Typography variant='text4' sx={{ color: isLightTheme ? 'base.800' : 'base.100', mr: 1 }}> */}
        {/*     У тебя еще 1 курс */}
        {/*   </Typography> */}
        {/*   <ArrowForward sx={{ color: isLightTheme ? 'base.800' : 'base.100' }} /> */}
        {/* </Card> */}
        <OtherCourseCards availableUserCards={availableUserCards} advertisingCards={advertisingCards} courseId={courseId!} />
        <CheerCard />
        <Button variant='contained' color='primary' subject={subject} onClick={onOpenPlaceholderModal}>
          Поделиться результами
        </Button>
      </Stack>
    </Card>
  );
};

export default CourseDashboard;
