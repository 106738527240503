import Container from 'layouts/Container';
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { BackgroundType, getBackground } from 'helpers/main';
import { CourseSubjectColor } from 'types/common';

interface IBackgroundLayoutProps {
  subject: CourseSubjectColor | undefined;
  children: React.ReactNode;
  backgroundType: BackgroundType;
}

const BackgroundLayout = ({ subject, children, backgroundType }: IBackgroundLayoutProps) => {
  const theme = useTheme();

  const background = getBackground(theme, subject, backgroundType);
  return (
    <Box
      component='div'
      sx={{
        background,
      }}
    >
      <Container maxWidth='md'>{children}</Container>
    </Box>
  );
};

export default BackgroundLayout;
