import { formatISODate } from 'helpers/formaters';
import { isAfter, isBefore } from 'date-fns';
import { UnavailableReason } from '@generated/graphql';
import { GetStatusParams } from './LessonCard.types';
import LESSON_STATUS_ICON from './LessonCard.constants';

export const isLessonMissed = (dateTo: string | null | undefined) => dateTo && isAfter(new Date(), new Date(dateTo));

export const generateDateText = (dateTo: string, dateFrom: string): string | undefined => {
  if (dateFrom && isBefore(new Date(), new Date(dateFrom))) {
    return `с ${formatISODate(dateFrom, 'dd.MM.yyyy')}`;
  }
  if (isLessonMissed(dateTo)) return `до ${formatISODate(dateTo, 'dd.MM.yyyy')}`;
  return undefined;
};

export const getStatus = ({ isLessonAvailable, completeness, subject, dateTo, unavailableReason }: GetStatusParams) => {
  const isUserHasLesson = isLessonAvailable || (unavailableReason !== UnavailableReason.NotPurchased && unavailableReason !== UnavailableReason.NotTrial);
  if (isUserHasLesson && completeness?.isComplete) return LESSON_STATUS_ICON.CHECKED(subject);
  if (isUserHasLesson && completeness?.isDone) return LESSON_STATUS_ICON.DONE;
  if (isLessonAvailable) return LESSON_STATUS_ICON.UNBLOCKED;
  if (isLessonMissed(dateTo)) return LESSON_STATUS_ICON.MISSED;
  return LESSON_STATUS_ICON.BLOCKED;
};
