import { ArrowForward } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useThemeMode from 'hooks/useThemeMode';
import { OnboardingCourseCardsQuery } from '@generated/graphql';

interface CourseCardButtonProps {
  course: OnboardingCourseCardsQuery['onboardingCourseCards'][number]['course'];
  isSelected: boolean;
}

const CourseCardButton = ({ course, isSelected }: CourseCardButtonProps) => {
  const { isLightTheme } = useThemeMode();
  const navigate = useNavigate();

  const { subject, id } = course;

  const handleNavigateToDescription = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigate(id);
  };

  const getBackgroundHoverColor = () => {
    if (isLightTheme) return 'base.100';
    if (isSelected) return 'base.600';
    return 'base.700';
  };

  return (
    <Button
      subject={subject}
      variant='contained'
      size='small'
      endIcon={<ArrowForward />}
      darkModeColor='dark'
      sx={{
        width: 'fit-content',
        mt: 1,
        backgroundColor: !isLightTheme && !isSelected ? 'base.600' : undefined,
        '&:hover': {
          backgroundColor: getBackgroundHoverColor(),
          color: isLightTheme ? 'base.900' : 'base.100',
        },
      }}
      onClick={handleNavigateToDescription}
    >
      О курсе
    </Button>
  );
};

export default CourseCardButton;
