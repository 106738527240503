import { Card, Stack } from '@mui/material';
import { BackButton } from 'components/index';
import ElementsGenerator from 'components/ElementsGenerator/ElementsGenerator';
import React, { FC } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { ArrayElement, IOutletContext } from 'types/common';
import { ApolloQueryResult } from '@apollo/client';
import { ElementType, Exact, ExerciseQuery, LessonQuery } from '@generated/graphql';
import ExerciseLink from 'components/ElementsGenerator/elements/ExerciseLink';
import { ExercisePageParams } from '../ExercisePage';
import LessonPagination from './LessonPagination';

export interface IExerciseOutletContext extends IOutletContext {
  homework: NonNullable<LessonQuery['lesson']['exercises']>[number];
  refetchLesson: (variables?: Partial<Exact<{ lessonId: string }>> | undefined) => Promise<ApolloQueryResult<LessonQuery>>;
  lesson: LessonQuery['lesson'];
}

type ElementsArrayType = ExerciseQuery['exercise']['elements'];
const Lecture: FC<{ exercise: ExerciseQuery['exercise'] }> = ({ exercise }) => {
  const navigate = useNavigate();
  const params = useParams<ExercisePageParams>();

  const elements: ElementsArrayType = exercise?.elements;
  const isExerciseLinkElementExist = elements?.findIndex((element) => element.type === ElementType.ExerciseLink) !== -1;

  return (
    <>
      <Card component={Stack} spacing={2.5}>
        <BackButton navigate={() => navigate(generatePath(`/${ROUTES.BLOCK}`, params))} text='Назад к урокам' />
        {/* @ts-ignore TODO item type */}
        {elements?.map((element: ArrayElement<ElementsArrayType>) => <ElementsGenerator key={`element-${element.id}`} type={element.type} item={element} />)}
        {!isExerciseLinkElementExist && <ExerciseLink />}
      </Card>
      <LessonPagination />
    </>
  );
};

export default Lecture;
