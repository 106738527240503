import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useGetLessonsQuery } from '@generated/graphql';
import { Button, IconButton, Stack } from '@mui/material';
import { KeyboardArrowLeft } from '@mui/icons-material';
import React from 'react';
import ROUTES from 'constants/routes';
import { IOutletContext } from 'types/common';

type LessonPaginationParams = { blockId: string; lessonId: string; courseId: string };

const LessonPagination = () => {
  const { subject } = useOutletContext<IOutletContext>();
  const navigate = useNavigate();
  const params = useParams<LessonPaginationParams>();
  const lessonId = params.lessonId as string;

  const { data } = useGetLessonsQuery({
    variables: {
      blockId: params.blockId!,
    },
  });

  const lessons = data?.block?.lessons;
  const currentLessonIndex = lessons?.length ? lessons?.findIndex((l) => l.id === lessonId) : -1;
  const isLessonExist = currentLessonIndex !== -1;
  const nextLesson = lessons?.[currentLessonIndex + 1];
  const prevLesson = lessons?.[currentLessonIndex - 1];
  const nextLessonLink =
    isLessonExist && nextLesson?.isAvailable && nextLesson?.exercises?.[0]?.isAvailable ? `${nextLesson.id}/${nextLesson?.exercises?.[0]?.id}` : undefined;
  const prevLessonLink =
    isLessonExist && currentLessonIndex !== 0 && prevLesson?.isAvailable && prevLesson?.exercises?.[0]?.isAvailable
      ? `${prevLesson?.id}/${prevLesson?.exercises?.[0]?.id}`
      : undefined;

  const navigateToLesson = (id: string) =>
    navigate(
      generatePath(`/${ROUTES.LESSON}`, {
        ...params,
        lessonId: id,
      }),
    );

  return (
    <Stack
      sx={{
        mt: 2,
        flex: 1,
      }}
      direction='row'
      spacing={1}
      alignItems='flex-end'
    >
      {prevLessonLink && (
        <IconButton variant='contained' onClick={() => navigateToLesson(prevLessonLink)} size='large'>
          <KeyboardArrowLeft />
        </IconButton>
      )}
      <Button
        variant='contained'
        fullWidth
        color='secondary'
        subject={subject}
        onClick={() => (nextLessonLink ? navigateToLesson(nextLessonLink) : navigate(generatePath(`/${ROUTES.BLOCK}`, params)))}
      >
        {nextLessonLink ? 'Следующий урок' : 'Вернуться к списку уроков'}
      </Button>
    </Stack>
  );
};

export default LessonPagination;
