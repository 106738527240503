import { SxProps } from '@mui/material';

export interface SliderProps {
  children: any;
  shift?: number;
  itemWidth: number;
  containerSx?: SxProps;
  header: string;
  headerProps: any;
  swipe?: boolean;
}

export enum Direction {
  PREV,
  NEXT,
}
export type DirectionType = Direction;

export interface CarouselState {
  pos: number;
  sliding: boolean;
  dir: DirectionType;
}

export type CarouselAction = { type: DirectionType; count: number } | { type: 'stopSliding' };
