import React, { FC } from 'react';
import { Box, CircularProgress, SxProps } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { IOutletContext } from 'types/common';

type CircularLoadingProps = {
  sx?: SxProps;
  size?: number;
};

const CircularLoading: FC<CircularLoadingProps> = ({ sx, size = 40 }) => {
  const outletContext = useOutletContext<IOutletContext>();
  const subject = outletContext?.subject;
  return (
    <Box sx={{ ...sx, display: 'flex', justifyContent: 'center' }} component='div'>
      <CircularProgress size={size} sx={(theme) => ({ color: subject ? theme.palette[subject].main : undefined })} />
    </Box>
  );
};

export default CircularLoading;

CircularLoading.defaultProps = {
  sx: {},
  size: 40,
};
