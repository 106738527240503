import { CarouselAction, CarouselState, Direction } from './types.d';

const reducer = (state: CarouselState, action: CarouselAction): CarouselState => {
  switch (action.type) {
    case Direction.PREV:
      return {
        ...state,
        dir: Direction.PREV,
        sliding: true,
        pos: state.pos === 0 ? action.count - 1 : state.pos - 1,
      };
    case Direction.NEXT:
      return {
        ...state,
        dir: Direction.NEXT,
        sliding: true,
        pos: state.pos === action.count - 1 ? 0 : state.pos + 1,
      };
    case 'stopSliding':
      return { ...state, sliding: false };
    default:
      return state;
  }
};

export default reducer;
