import { TypographyOptions } from '@mui/material/styles/createTypography';

export default {
  fontFamily: 'Inter',
  h1: {
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700,
  },
  'h1.0': {
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 700,
    '@media (max-width: 699px)': {
      fontSize: 20,
    },
  },
  'h1.1': {
    fontSize: 82,
    lineHeight: 1.04,
    fontWeight: 700,
    '@media (max-width: 1339px)': {
      fontSize: 60,
    },
    '@media (max-width: 699px)': {
      fontSize: 40,
      lineHeight: 1.1,
    },
  },
  'h1.2': {
    fontSize: 30,
    lineHeight: 1,
    fontWeight: 700,
  },
  h2: {
    fontSize: 26,
    lineHeight: 1.2,
    fontWeight: 700,
  },
  'h2.1': {
    fontSize: 70,
    lineHeight: 1.1,
    fontWeight: 700,
    '@media (max-width: 1439px)': {
      fontSize: 67,
    },
    '@media (max-width: 1339px)': {
      fontSize: 60,
    },
    '@media (max-width: 1019px)': {
      fontSize: 55,
    },
    '@media (max-width: 699px)': {
      fontSize: 40,
      lineHeight: 1,
    },
  },
  h3: {
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 600,
  },
  'h3.1': {
    fontSize: 58,
    lineHeight: 1,
    fontWeight: 700,
    '@media (max-width: 1339px)': {
      fontSize: 44,
    },
    '@media (max-width: 1019px)': {
      fontSize: 38,
    },
    '@media (max-width: 699px)': {
      fontSize: 32,
    },
  },
  h4: {
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  'h4.1': {
    fontSize: 70,
    lineHeight: 1.1,
    fontWeight: 700,
    '@media (max-width: 1339px)': {
      fontSize: 60,
    },
    '@media (max-width: 1019px)': {
      fontSize: 56,
    },
    '@media (max-width: 699px)': {
      fontSize: 48,
    },
  },
  h5: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 600,
  },
  h6: {
    fontSize: 18,
    lineHeight: 1,
    fontWeight: 500,
  },
  h7: {
    fontSize: 64,
    lineHeight: 0.9,
    fontWeight: 500,
  },
  h8: {
    fontSize: 35,
    lineHeight: 0.9,
    fontWeight: 500,
  },
  text1: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  'text1.1': {
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 400,
    '@media (max-width: 1340px)': {
      fontSize: 22,
    },
    '@media (max-width: 1019px)': {
      fontSize: 20,
    },
    '@media (max-width: 699px)': {
      fontSize: 14,
    },
  },
  'text1.2': {
    fontSize: 24,
    lineHeight: 1.2,
    fontWeight: 400,
    '@media (max-width: 1340px)': {
      fontSize: 22,
    },
    '@media (max-width: 1019px)': {
      fontSize: 20,
    },
    '@media (max-width: 699px)': {
      fontSize: 16,
    },
  },
  text2: {
    fontSize: 18,
    lineHeight: 1.3,
    fontWeight: 600,
  },
  'text2.1': {
    fontSize: 18,
    lineHeight: 1,
    fontWeight: 700,
  },
  'text2.2': {
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  text3: {
    fontSize: 16,
    lineHeight: 1.3,
    fontWeight: 500,
  },
  text4: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  text5: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 400,
  },
  text6: {
    fontSize: 15,
    lineHeight: 1.2,
    fontWeight: 500,
  },
  text7: {
    fontSize: 14,
    lineHeight: 1.2,
    fontWeight: 500,
  },
} as TypographyOptions;
