import { useNavigate } from 'react-router-dom';
import { Card, Stack, Typography } from '@mui/material';
import Capybara from 'pages/Onboarding/components/Capybara';
import InfoBanner from 'components/dashboard/InfoBanner';
import { ArrowForward } from '@mui/icons-material';
import React from 'react';

const GRADES = [
  { text: '8 класс', value: 8 },
  { text: '9 класс', value: 9 },
  { text: '10 класс', value: 10 },
  { text: '11 класс', value: 11 },
];

const SelectGrade = () => {
  const navigate = useNavigate();

  const handleGradeSelect = (grade: number) => {
    navigate(grade.toString());
  };

  return (
    <Card>
      <Stack spacing={2.5}>
        <Capybara />
        <Typography variant='h2'>Выбери класс:</Typography>
        {GRADES.map((grade) => (
          <InfoBanner icon={<ArrowForward />} key={grade.value} text={grade.text} onClick={() => handleGradeSelect(grade.value)} />
        ))}
      </Stack>
    </Card>
  );
};

export default SelectGrade;
