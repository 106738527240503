import React, { FC } from 'react';
import { IconButton, Stack } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const ChartPagination: FC<{
  firstColumnNumber: number;
  setScores: (scores: any[]) => any;
  initialScores: any[];
  setFirstColumnNumber: (column: number) => any;
}> = ({ firstColumnNumber, setScores, initialScores, setFirstColumnNumber }) => {
  const totalCount: number = initialScores?.length || 0;

  const updateScoreArray = (columnNumber: number) => {
    const lastColumnNumber = columnNumber + (totalCount - columnNumber + 5 < 5 ? totalCount - columnNumber + 5 : 5);
    setScores(initialScores.slice(columnNumber, lastColumnNumber));
    setFirstColumnNumber(columnNumber);
  };

  const handleNextPage = () => {
    const columnNumber = firstColumnNumber + 5 < totalCount ? firstColumnNumber + 5 : totalCount - firstColumnNumber;
    updateScoreArray(columnNumber);
  };

  const handlePreviousPage = () => {
    const columnNumber = firstColumnNumber - 5 < 0 ? 5 - firstColumnNumber : firstColumnNumber - 5;
    updateScoreArray(columnNumber);
  };

  return (
    <Stack spacing={1} direction='row'>
      <IconButton
        variant='contained'
        size='small'
        onClick={handlePreviousPage}
        disabled={firstColumnNumber === 0}
        sx={{
          '& > svg': {
            width: '2rem',
            height: '2rem',
          },
        }}
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        variant='contained'
        size='small'
        onClick={handleNextPage}
        disabled={initialScores.length <= firstColumnNumber + 5}
        sx={{
          '& > svg': {
            width: '2rem',
            height: '2rem',
          },
        }}
      >
        <KeyboardArrowRight />
      </IconButton>
    </Stack>
  );
};

export default ChartPagination;
