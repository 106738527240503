import { Box, Typography } from '@mui/material';
import UploadFileImage from 'assets/images/upload-file.svg';
import { UseFormReturn } from 'react-hook-form';

const File = ({ name, form, disabled }: { name: string; form: UseFormReturn; disabled?: boolean }) => {
  const { register, watch } = form;

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label>
      <input type='file' style={{ display: 'none' }} {...register(name)} disabled={disabled} />
      <Box
        component='div'
        sx={(theme) => ({
          boxShadow: theme.palette.mode === 'light' ? 1 : 2,
          height: 85,
          px: 2,
          borderRadius: 4,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'baseShift.100',
          cursor: 'pointer',
        })}
      >
        <Box component='div' sx={{ mr: 2, width: 40, height: 40 }}>
          <img src={UploadFileImage} alt='document' />
        </Box>
        <Typography variant='text3' sx={{ whiteSpace: 'pre-wrap' }}>
          {watch(name) ? 'Файл загружен' : 'Нажми, чтобы\nзагрузить файл'}
        </Typography>
      </Box>
    </label>
  );
};

export default File;
