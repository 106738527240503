import { Close, Done, DoneAll, LockOutlined } from '@mui/icons-material';
import { CourseSubject } from '@generated/graphql';

const LESSON_STATUS_ICON = {
  CHECKED: (subject: CourseSubject) => <DoneAll sx={(theme) => ({ color: theme.palette[subject].main })} />,
  BLOCKED: <LockOutlined sx={{ color: 'base.300' }} />,
  UNBLOCKED: null,
  MISSED: <Close sx={{ color: 'error.main' }} />,
  DONE: <Done />,
};

export default LESSON_STATUS_ICON;
