import { useController, UseControllerProps } from 'react-hook-form';
import { TextField, TextFieldProps, InputAdornment, SxProps } from '@mui/material';
import { FC, ForwardedRef, ReactNode, forwardRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { IMaskInput } from 'react-imask';

type TextFieldControlProps = {
  name: string;
  endIcon?: ReactNode;
  handleChange?: (value: string, onChange: any) => any;
  isError?: boolean;
  sx?: SxProps;
  label?: string;
  maskType?: 'phone';
};

const MaskCustom = forwardRef((props: any, ref: ForwardedRef<any>) => {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      inputRef={ref}
      onAccept={(value) => {
        onChange({ target: { name: props.name, value } });
      }}
      unmask
    />
  );
});

const TextFieldControl: FC<Omit<TextFieldProps, 'label'> & TextFieldControlProps & UseControllerProps> = ({
  name,
  placeholder,
  variant,
  size,
  rules,
  handleChange,
  maskType,
  ...props
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ defaultValue: '', name, rules });

  let mask;

  if (maskType) {
    switch (maskType) {
      case 'phone': {
        mask = { mask: '+{7} (000) 000-00-00' };
        break;
      }
      default:
        mask = null;
    }
  }

  return (
    <TextField
      {...props}
      {...field}
      onChange={(e) => {
        const { value } = e.target;
        if (handleChange) handleChange(value, field.onChange);
        else field.onChange(value);
      }}
      disabled={props.disabled}
      size={size}
      error={!!error || props.isError}
      fullWidth
      placeholder={placeholder}
      variant={variant}
      slotProps={{
        input: {
          inputProps: { ...mask },
          inputComponent: mask ? MaskCustom : undefined,
          endAdornment: props.endIcon && <InputAdornment position='end'>{props.endIcon}</InputAdornment>,
        },
      }}
    />
  );
};

export default TextFieldControl;
