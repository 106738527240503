import { format, parseISO } from 'date-fns';

const formatNumber = (number: number, titles: string[], onlyTitle?: boolean) => {
  // titles example ['день', 'дня', 'дней']
  const cases = [2, 0, 1, 1, 1, 2];
  // eslint-disable-next-line no-param-reassign
  if (!number) number = 0;
  return (
    (onlyTitle ? '' : `${number} `) +
    titles[Math.abs(number) % 100 > 4 && Math.abs(number) % 100 < 20 ? 2 : cases[Math.abs(number) % 10 < 5 ? Math.abs(number) % 10 : 5]]
  );
};

const createUserName = (
  user:
    | {
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        middleName?: string | null | undefined;
      }
    | undefined
    | null,
) => {
  if (!user) return '—';
  const { firstName, lastName, middleName } = user;
  return firstName || lastName || middleName ? [firstName, lastName, middleName].join(' ') : '—';
};

const formatISODate = (dateISO: string, dateFormat: string = 'dd.MM.yyyy') => format(parseISO(dateISO), dateFormat);

const generateBase64 = (tgUserData: string) => {
  const bytes = new TextEncoder().encode(decodeURIComponent(tgUserData));
  // @ts-ignore
  return btoa(String.fromCodePoint(...bytes));
};

export { formatISODate, formatNumber, createUserName, generateBase64 };
