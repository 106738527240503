import BiologyPromoImg from 'assets/images/biology/biology-promo.png';
import ChemistryPromoImg from 'assets/images/chemistry/chemistry-promo.png';
import { CourseSubject } from '@generated/graphql';

const SUBJECT = {
  [CourseSubject.Biology]: {
    name: 'Биология',
    description: 'Привет! Я Илья Биолог - практикующий ученый, преподаватель и эксперт ЕГЭ по биологии.',
    backgroundImage: BiologyPromoImg,
  },
  [CourseSubject.Chemistry]: {
    name: 'Химия',
    description: 'Привет! На связи Дима Вюрц - преподаватель химии на платформе EDKIT.',
    backgroundImage: ChemistryPromoImg,
  },
  [CourseSubject.Math]: {
    name: 'Математика',
    description: '',
    backgroundImage: BiologyPromoImg,
  },
  [CourseSubject.Physics]: {
    name: 'Физика',
    description: '',
    backgroundImage: BiologyPromoImg,
  },
  [CourseSubject.RussianLanguage]: {
    name: 'Русский язык',
    description: '',
    backgroundImage: BiologyPromoImg,
  },
  [CourseSubject.Literature]: {
    name: 'Литература',
    description: '',
    backgroundImage: BiologyPromoImg,
  },
  [CourseSubject.Informatics]: {
    name: 'Информатика',
    description: '',
    backgroundImage: BiologyPromoImg,
  },
  [CourseSubject.History]: {
    name: 'История',
    description: '',
    backgroundImage: BiologyPromoImg,
  },
  [CourseSubject.SocialScience]: {
    name: 'Обществознание',
    description: '',
    backgroundImage: BiologyPromoImg,
  },
};

export default SUBJECT;
