/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/applicant_lvl_13_emb.gltf
*/

/*
eslint-disable react/no-unknown-property
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import startAnimation from '../helpers/startAnimation';

const ApplicantLvl13 = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/applicant_lvl_13_emb.gltf');
  const { actions, names } = useAnimations(animations, group);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    startAnimation(actions, names);
  }, [actions, names]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <group name='ARMA_applicant_lvl_13'>
          <primitive object={nodes.pivot} />
          <primitive object={nodes.pelvis} />
          <skinnedMesh
            castShadow
            name='MESH_applicant_lvl_13'
            geometry={nodes.MESH_applicant_lvl_13.geometry}
            material={materials.Texture_capibara}
            skeleton={nodes.MESH_applicant_lvl_13.skeleton}
          />
          <skinnedMesh
            castShadow
            name='Plane003'
            geometry={nodes.Plane003.geometry}
            material={materials.Texture_applicant_lvl_13}
            skeleton={nodes.Plane003.skeleton}
          />
        </group>
        <group name='ARMA_balloon_2'>
          <primitive object={nodes.Bone005} />
          <skinnedMesh name='Sphere001' geometry={nodes.Sphere001.geometry} material={materials.Texture_applicant_lvl_13} skeleton={nodes.Sphere001.skeleton} />
        </group>
        <group name='ARMA_balloon_1'>
          <primitive object={nodes.Bone005_1} />
          <skinnedMesh name='Sphere002' geometry={nodes.Sphere002.geometry} material={materials.Texture_applicant_lvl_13} skeleton={nodes.Sphere002.skeleton} />
        </group>
        <group name='ARMA_balloon_4'>
          <primitive object={nodes.Bone005_2} />
          <skinnedMesh name='Sphere004' geometry={nodes.Sphere004.geometry} material={materials.Texture_applicant_lvl_13} skeleton={nodes.Sphere004.skeleton} />
        </group>
        <group name='ARMA_balloon_3'>
          <primitive object={nodes.Bone005_3} />
          <skinnedMesh name='Sphere005' geometry={nodes.Sphere005.geometry} material={materials.Texture_applicant_lvl_13} skeleton={nodes.Sphere005.skeleton} />
        </group>
        <group name='ARMA_balloon_5'>
          <primitive object={nodes.Bone005_4} />
          <skinnedMesh name='Sphere006' geometry={nodes.Sphere006.geometry} material={materials.Texture_applicant_lvl_13} skeleton={nodes.Sphere006.skeleton} />
        </group>
      </group>
    </group>
  );
};

export default ApplicantLvl13;

useGLTF.preload('/applicant_lvl_13_emb.gltf');
