import Slider from 'components/Slider';
import PromoSubjectCard from 'components/dashboard/PromoSubjectCard';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CourseCardType, CourseSubject, useGetTrialMutation } from '@generated/graphql';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

export type CardType = {
  __typename?: 'CourseCardLkDto';
  id: string;
  description: string;
  useDefaultCover: boolean;
  type: CourseCardType;
  link?: string | null;
  coverFile?: { __typename?: 'FileLkDto'; url: string } | null;
  course: {
    __typename?: 'CoursePresentationLkDto';
    id: string;
    title?: string | null;
    subject: CourseSubject;
    coverFile?: { __typename?: 'FileLkDto'; url: string } | null;
  };
};

interface CourseSliderProps {
  courses: CardType[] | undefined;
  header: string;
}

const CourseSlider = ({ courses, header }: CourseSliderProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [slideWidth, setSlideWidth] = useState<number>(0);
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const [getTrial] = useGetTrialMutation();
  const goToCourse = (courseId: string, cardType: CourseCardType) => {
    if (cardType === CourseCardType.Advertising) {
      getTrial({
        variables: {
          coursesIds: [courseId],
        },
      })
        .then(() => navigate(`/course/${courseId}`))
        .catch(() => addToast({ type: ToastTypeEnum.ERROR }));
    } else navigate(`/course/${courseId}`);
  };
  useEffect(() => {
    if (ref.current) {
      setSlideWidth(ref.current.offsetWidth);
    }
  }, [ref.current]);

  return (
    <Box component='div' ref={ref} sx={{ mb: 3.5 }}>
      <Slider
        swipe
        itemWidth={slideWidth}
        containerSx={{
          '& > div:first-of-type': {
            mt: 0.5,
          },
          '& > div > .slide-element:first-of-type': { marginLeft: 2 },
        }}
        header={header}
        headerProps={{ variant: 'h1', sx: { whiteSpace: 'pre-wrap' } }}
      >
        {courses?.map((card) => (
          <PromoSubjectCard
            title={card.course.title}
            description={card.description}
            buttonText='На страницу курса'
            containerSx={{ minHeight: 162, height: 'auto', minWidth: slideWidth }}
            subject={card.course.subject}
            backgroundImage={card.useDefaultCover ? card.course.coverFile?.url : card.coverFile?.url}
            handleButtonClick={() => goToCourse(card.course.id, card.type)}
          />
        ))}
      </Slider>
    </Box>
  );
};

export default CourseSlider;
