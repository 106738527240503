import { Button, Card, Stack } from '@mui/material';
import Text from 'components/ElementsGenerator/elements/Text';
import { CourseCardType, useCourseCardQuery } from '@generated/graphql';
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import CircularLoading from 'components/CircularLoading';
import { BackButton } from 'components/index';
import PromoSubjectCard from 'components/dashboard/PromoSubjectCard';
import { OnboardingOutletProps } from 'pages/Onboarding/OnboardingOutlet';
import { Done } from '@mui/icons-material';
import ROUTES from 'constants/routes';
import InfoBanner from 'components/dashboard/InfoBanner';

const CourseDescription = () => {
  const navigate = useNavigate();
  const { selectedCoursesByGrade } = useOutletContext<OnboardingOutletProps>();
  const params = useParams<{
    courseId: string;
    grade: string;
  }>();

  const grade = params.grade as string;
  const courseId = params.courseId as string;

  const { data, loading } = useCourseCardQuery({
    variables: {
      courseId: courseId!,
      cardType: CourseCardType.Onboarding,
    },
  });

  const courseCard = data?.courseCard;
  const course = courseCard?.course;
  const subject = course?.subject;
  const coverFile = courseCard?.useDefaultCover ? course?.coverFile?.url : courseCard?.coverFile?.url;
  const backToSelectCourse = () => navigate(generatePath('/onboarding/select-grade/:grade', { grade: grade! }));

  const selectedCourses = selectedCoursesByGrade[grade] || [];

  const isIdAlreadyExist = selectedCourses?.includes(courseId);

  if (loading) return <CircularLoading />;

  return (
    <Stack spacing={2.5} sx={{ flex: 1 }}>
      <Card>
        <BackButton navigate={backToSelectCourse} text='Назад' sx={{ mb: 1.5 }} />
        <PromoSubjectCard subject={subject} title={course?.title} description={`${course?.grade} класс`} backgroundImage={coverFile} />
        {isIdAlreadyExist && <InfoBanner icon={<Done />} text='Вы выбрали данный курс' containerSx={{ mt: 2 }} />}
      </Card>
      <Card>
        <Text content={courseCard?.description!} sx={{ '& ul': { pl: 2 } }} />
      </Card>
      <Button variant='contained' color='secondary' subject={subject} onClick={() => navigate(`/${ROUTES.ONBOARDING}/${ROUTES.SELECT_GRADE}/${grade}`)}>
        К списку курсов
      </Button>
    </Stack>
  );
};

export default CourseDescription;
