import { Box, IconButton, Modal } from '@mui/material';
import { Close, ZoomIn, ZoomOut } from '@mui/icons-material';
import React, { useEffect, useRef, useState, TouchEvent } from 'react';

interface ILightboxProps {
  open: boolean;
  onClose: () => void;
  image: string;
}

type LightboxContentProps = Omit<ILightboxProps, 'open'>;

interface IPosition {
  x: number;
  y: number;
}

const DEFAULT_POSITION = { x: 0, y: 0 };

const LightboxContent = ({ onClose, image }: LightboxContentProps) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [zoomState, setZoomState] = useState<number>(1);

  const [isMovingImage, setIsMovingImage] = useState<boolean>(false);
  const [imagePosition, setImagePosition] = useState<IPosition>(DEFAULT_POSITION);
  const [startPosition, setStartPosition] = useState<IPosition>(DEFAULT_POSITION);

  const toggleZoomIn = () => {
    setZoomState((prevState) => {
      if (prevState < 3) {
        return prevState + 0.25;
      }
      return prevState;
    });
  };

  const toggleZoomOut = () => {
    setZoomState((prevState) => {
      if (prevState > 1) {
        return prevState - 0.25;
      }
      return prevState;
    });

    if (zoomState <= 1.25) {
      setImagePosition({ x: 0, y: 0 }); // Reset image position when zoomed out completely
    }
  };

  const onPinchZoom = (event: WheelEvent) => {
    event.preventDefault();
    if (event.deltaY < 0) toggleZoomIn();
    else toggleZoomOut();
  };

  const getCoordinates = (event: React.MouseEvent | TouchEvent<HTMLImageElement>) => {
    const clientX = 'clientX' in event ? event.clientX : event.touches[0].clientX;
    const clientY = 'clientY' in event ? event.clientY : event.touches[0].clientY;

    return { clientX, clientY };
  };

  const onStartMovingImage = (event: React.MouseEvent | TouchEvent<HTMLImageElement>) => {
    if (!('targetTouches' in event) || ('targetTouches' in event && event.targetTouches.length === 1)) {
      const { clientX, clientY } = getCoordinates(event);
      setIsMovingImage(true);
      setStartPosition({ x: clientX, y: clientY });
    }
  };

  const onStopMovingImage = () => {
    setIsMovingImage(false);
  };

  const onMoveImage = (event: React.MouseEvent | TouchEvent<HTMLImageElement>) => {
    if (isMovingImage) {
      const { clientX, clientY } = getCoordinates(event);
      const deltaX = clientX - startPosition.x;
      const deltaY = clientY - startPosition.y;

      setImagePosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));

      setStartPosition({ x: clientX, y: clientY });
    }
  };

  useEffect(() => {
    const imgRefCurrent = imgRef.current;
    imgRefCurrent?.addEventListener('wheel', onPinchZoom, { passive: false });
    return () => {
      imgRefCurrent?.removeEventListener('wheel', onPinchZoom);
    };
  }, [imgRef.current]);

  return (
    <>
      <Box
        component='div'
        sx={{
          zIndex: 100,
          display: 'flex',
          justifyContent: 'flex-end',
          backgroundColor: 'base.600',
          opacity: 0.6,
          position: 'fixed',
          top: 0,
          width: '100vw',
        }}
      >
        <IconButton onClick={toggleZoomIn} sx={{ color: 'base.100' }}>
          <ZoomIn />
        </IconButton>
        <IconButton onClick={toggleZoomOut} sx={{ color: 'base.100' }}>
          <ZoomOut />
        </IconButton>
        <IconButton onClick={onClose} sx={{ color: 'base.100' }}>
          <Close />
        </IconButton>
      </Box>
      <Box
        ref={imgRef}
        component='img'
        src={image}
        draggable={false}
        alt='Lightbox Image'
        sx={{
          transform: `scale(${zoomState}) translate(${imagePosition.x}px, ${imagePosition.y}px)`,
          cursor: isMovingImage ? 'grabbing' : 'grab',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
        onMouseDown={onStartMovingImage}
        onMouseUp={onStopMovingImage}
        onMouseLeave={onStopMovingImage}
        onMouseMove={onMoveImage}
        onTouchStart={onStartMovingImage}
        onTouchEnd={onStopMovingImage}
        onTouchMove={onMoveImage}
      />
    </>
  );
};

const Lightbox = ({ open, onClose, image }: ILightboxProps) => (
  <Modal open={open} onClose={onClose} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2 }}>
    <LightboxContent onClose={onClose} image={image} />
  </Modal>
);

export default Lightbox;
