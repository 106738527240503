/* eslint-disable */
import { ApolloClient, ApolloLink, from, InMemoryCache, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import cookies from 'js-cookie';
import { webApp } from 'hooks/useTelegram';
import { getItem, removeItem, setItem } from 'helpers/cloudStorage';
import ROUTES from 'constants/routes';

const isTelegram = webApp.platform !== 'unknown';
const graphqlLink = `${process.env.REACT_APP_API_URL}/graphql`;

const httpLink = createUploadLink({
  uri: graphqlLink,
  fetchOptions: {
    credentials: 'include',
  },
  credentials: 'include',
  headers: { 'Apollo-Require-Preflight': 'true' },
}) as unknown as ApolloLink;

const getToken = async (key: string) => {
  if (isTelegram) {
    return await getItem(key);
  }
  return cookies.get(key);
};

const setToken = async (key: string, value: string) => {
  if (isTelegram) {
    await setItem(key, value);
  } else {
    cookies.set(key, value);
  }
};

const removeToken = async (key: string) => {
  if (isTelegram) {
    await removeItem(key);
  } else {
    cookies.remove(key);
  }
};

const authMiddleware = setContext(async (_, { headers = {} }) => {
  const token = await getToken('accessToken');

  return {
    headers: {
      ...headers,
      authorization: token ? `JWT ${token}` : '',
    },
  };
});

let isRefreshing = false;
let failedRequestsQueue: Array<{ resolve: (token: string | null) => void; reject: (error: any) => void }> = [];

const processQueue = async (error: any, token: string | null = null) => {
  failedRequestsQueue.forEach((prom) => (error ? prom.reject(error) : prom.resolve(token)));
  failedRequestsQueue = [];
  isRefreshing = false;
};

const refreshAccessToken = async () => {
  const accessToken = await getToken('accessToken');
  const refreshToken = await getToken('refreshToken');

  if (!accessToken || !refreshToken) {
    return null;
  }

  const response = await fetch(graphqlLink, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `JWT ${accessToken}`,
    },
    body: JSON.stringify({
      query: `mutation refreshTokens {refreshTokens(refreshToken: "${refreshToken}") {
                                            accessToken
                                            refreshToken
                                        }}`,
    }),
  });

  if (!response.ok) {
    throw new Error('Ошибка при обновлении токена');
  }

  return await response.json();
};

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ extensions, path }) => {
      console.log(`[GraphQL error]: Message: ${extensions?.message}, Path: ${path}`);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }

  const isMeQuery = operation.operationName === 'me';
  const isLoginByTelegramTokenMutation = operation.operationName === 'loginByTelegramToken';
  const isSignUpPage = location.pathname.includes(ROUTES.SIGNUP);

  const unauthenticatedError = graphQLErrors?.find(
    ({ extensions }) => extensions?.code === 'UNAUTHENTICATED' && !isLoginByTelegramTokenMutation && (!isMeQuery || (isMeQuery && !isSignUpPage)),
  );

  if (unauthenticatedError) {
    return new Observable((observer) => {
      new Promise<string | null>((resolve, reject) => {
        failedRequestsQueue.push({ resolve, reject });
      })
        .then((token) => {
          if (token) {
            const subscriber = {
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            };
            forward(operation).subscribe(subscriber);
          }
        })
        .catch(() => {
          // Обработка неудачного обновления токенов
        });

      if (!isRefreshing) {
        isRefreshing = true;
        refreshAccessToken()
          .then(async (data) => {
            if (data?.errors?.length) {
              throw new Error('Ошибка обновления токена');
            }

            const { accessToken, refreshToken } = data.data.refreshTokens;
            await setToken('accessToken', accessToken);
            await setToken('refreshToken', refreshToken);

            await processQueue(null, accessToken);
          })
          .catch(async (e) => {
            console.log('error', e);
            await processQueue(e, null);

            await removeToken('accessToken');
            await removeToken('refreshToken');

            if (!isTelegram) {
              window.location.href = `/signup?redirectUrl=${encodeURIComponent(window.location.pathname + window.location.search)}`;
            }
          });
      }
    });
  }
});

const client = new ApolloClient({
  link: from([errorLink, authMiddleware, httpLink]),
  cache: new InMemoryCache(),
});

export default client;
