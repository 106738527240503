import React, { createContext, ReactNode, useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { MeQuery, useMeQuery } from '@generated/graphql';

export type AuthContextType = {
  me?: MeQuery['me'] | null;
  loading: boolean;
  error?: ApolloError;
};
export const AuthContext = createContext({} as AuthContextType);

type AuthProviderProps = {
  children: ReactNode;
};
export const AuthProvider = ({ children }: AuthProviderProps) => {
  const { data, loading, error } = useMeQuery();
  const me = data?.me;
  const providerValue = useMemo(() => ({ me, loading, error }), [me, loading, error]);

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};
