import { useContext, useState } from 'react';
import { Box, Card, Link, Stack, Typography, useTheme } from '@mui/material';
import CheckboxControl from 'components/form/CheckboxControl';
// import TextFieldControl from 'components/form/TextFieldControl';
import { useForm } from 'react-hook-form';
import { Form } from 'components/form/Form';
// import { ArrowForward } from '@mui/icons-material';
import { boolean, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import { CourseCardType, CourseSubject, ResourceType, useBlockPresentationQuery, useCourseCardQuery, useInitPaymentMutation } from '@generated/graphql';
import CircularLoading from 'components/CircularLoading';
import { LoadingButton } from '@mui/lab';
import PaymentCard, { PaymentCardCourse } from 'pages/Payment/PaymentCard';
import ROUTES from 'constants/routes';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';

interface IFormInputs {
  publicOffer: boolean;
  personalData: boolean;
  promocode?: string;
}

const validation = object().shape({
  publicOffer: boolean().oneOf([true]),
  personalData: boolean().oneOf([true]),
});
const Payment = () => {
  const { palette } = useTheme();
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { courseId, blockId } = useParams<{ courseId: string; blockId: string }>();
  const { data, loading, error } = useCourseCardQuery({
    variables: {
      courseId: courseId!,
      cardType: CourseCardType.Purchase,
    },
  });

  const { data: blockData, loading: blockLoading } = useBlockPresentationQuery({
    variables: {
      blockId: blockId!,
    },
    skip: !blockId,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const block = blockData?.blockPresentation;
  const courseCard = data?.courseCard;
  const course = data?.courseCard.course;
  const subject = course?.subject as CourseSubject;
  const coverFile = courseCard?.useDefaultCover ? course?.coverFile : courseCard?.coverFile;

  const [initPayment, { loading: paymentLoading }] = useInitPaymentMutation();

  const [price] = useState(undefined);
  // const [showPromocodeButton, setShowPromocodeButton] = useState<boolean>(true);
  const form = useForm<IFormInputs>({
    resolver: yupResolver<any>(validation),
  });
  const {
    formState: { isValid },
    // watch,
    // clearErrors
  } = form;

  const cost = blockId ? block?.cost : course?.cost;

  // const checkPromocode = () => {
  //   setShowPromocodeButton(false);
  //   const promocode = watch('promocode');
  //   console.log(promocode);
  // };

  const onPay = () => {
    initPayment({
      variables: {
        input: {
          resourceId: blockId || courseId!,
          resourceType: blockId ? ResourceType.Block : ResourceType.Course,
        },
      },
    })
      .then((res: any) => {
        const link = res.data.initPayment;
        if (link === 'true') navigate(`/${ROUTES.SUCCESS_PAYMENT}?courseId=${courseId}`);
        else window.location.href = link;
      })
      .catch((err) => {
        addToast({ text: err.message, type: ToastTypeEnum.ERROR });
      });
  };

  const subjectColor = palette[subject]?.main;

  if (loading || blockLoading) return <CircularLoading />;
  if (error?.message) return <Typography>{error?.message || 'Курс не найден'}</Typography>;

  return (
    <>
      <Stack spacing={2.5}>
        <PaymentCard
          course={course as PaymentCardCourse}
          description={courseCard?.description}
          coverFile={coverFile}
          cardDescription={block?.title ? `Блок: ${block.title}` : undefined}
        />
        <Card>
          <Form form={form}>
            <Stack sx={{ position: 'relative' }}>
              <CheckboxControl
                label={
                  <>
                    Условия&nbsp;
                    <Link target='_blank' sx={{ color: subjectColor, textDecorationColor: subjectColor }} href='/docs/ПУБЛИЧНАЯ ОФЕРТА.pdf'>
                      публичной оферты
                    </Link>
                  </>
                }
                name='publicOffer'
                subject={subject}
              />
              <CheckboxControl
                label={
                  <>
                    Условия&nbsp;
                    <Link
                      target='_blank'
                      sx={{ color: subjectColor, textDecorationColor: subjectColor }}
                      href='/docs/Согласие_на_обработку_персональных_данных.pdf'
                    >
                      обработки персональных данных
                    </Link>
                  </>
                }
                name='personalData'
                subject={subject}
              />
              {/* <TextFieldControl */}
              {/*   name='promocode' */}
              {/*   sx={{ mt: 2.5 }} */}
              {/*   placeholder='ПРОМОКОД' */}
              {/*   handleChange={(value: string, onChange: any) => { */}
              {/*     if (!showPromocodeButton) { */}
              {/*       setShowPromocodeButton(true); */}
              {/*       clearErrors('promocode'); */}
              {/*     } */}
              {/*     onChange(value); */}
              {/*   }} */}
              {/*   endIcon={ */}
              {/*     showPromocodeButton ? ( */}
              {/*       <IconButton edge='end' onClick={checkPromocode}> */}
              {/*         <ArrowForward /> */}
              {/*       </IconButton> */}
              {/*     ) : ( */}
              {/*       <div /> */}
              {/*     ) */}
              {/*   } */}
              {/* /> */}
              {price && (
                <Typography
                  sx={(theme) => ({
                    position: 'absolute',
                    bottom: 35,
                    right: 0,
                    textDecoration: 'line-through',
                    textDecorationColor: theme.palette.error.main,
                  })}
                  variant='text2.2'
                >
                  {price}
                </Typography>
              )}
              <Stack mt={4} direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant='h6'>Итоговая сумма</Typography>
                <Typography variant='h4'>{`${cost}₽`}</Typography>
              </Stack>
            </Stack>
          </Form>
        </Card>
      </Stack>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'flex-end',
          mt: 1,
        }}
        component='div'
      >
        <LoadingButton loading={paymentLoading} variant='contained' fullWidth color='secondary' disabled={!isValid} onClick={onPay} subject={subject}>
          Оплатить
        </LoadingButton>
      </Box>
    </>
  );
};

export default Payment;
