/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/explorer_2_lvl_6.gltf
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import startAnimation from '../helpers/startAnimation';

/*
eslint-disable react/no-unknown-property
*/

const Explorer = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/explorer_2_lvl_6.gltf');
  const { actions, names } = useAnimations(animations, group);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    startAnimation(actions, names);
  }, [actions, names]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <group name='ARMA_explorer_2_lvl_6' position={[0.937, 0, 0.514]} rotation={[Math.PI, -1.159, Math.PI]}>
          <primitive object={nodes.pelvis} />
          <skinnedMesh
            castShadow
            name='MESH_explorer_2_lvl_6'
            geometry={nodes.MESH_explorer_2_lvl_6.geometry}
            material={materials['Texture_capibara.005']}
            skeleton={nodes.MESH_explorer_2_lvl_6.skeleton}
          />
          <group name='shlyapa'>
            <skinnedMesh castShadow name='Sphere' geometry={nodes.Sphere.geometry} material={materials.Material} skeleton={nodes.Sphere.skeleton} />
            <skinnedMesh castShadow name='Sphere_1' geometry={nodes.Sphere_1.geometry} material={materials.brown} skeleton={nodes.Sphere_1.skeleton} />
          </group>
        </group>
        <group name='ARMA_footprints' position={[0, -0.017, 0]}>
          <primitive object={nodes.front_r} />
          <primitive object={nodes.front_l} />
          <primitive object={nodes.back_r} />
          <primitive object={nodes.back_l} />
          <skinnedMesh name='MESH_footprints' geometry={nodes.MESH_footprints.geometry} material={materials.black} skeleton={nodes.MESH_footprints.skeleton} />
        </group>
      </group>
    </group>
  );
};

export default Explorer;

useGLTF.preload('/explorer_2_lvl_6.gltf');
