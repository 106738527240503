import { Button, Card, Stack, Typography } from '@mui/material';
import Capybara from 'pages/Onboarding/components/Capybara';
import ROUTES from 'constants/routes';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { BackButton } from 'components/index';
import { OnboardingOutletProps } from 'pages/Onboarding/OnboardingOutlet';

const NoCourseAvailable = () => {
  const { subject } = useOutletContext<OnboardingOutletProps>();
  const navigate = useNavigate();
  const backToSelectGrade = () => navigate(`/${ROUTES.ONBOARDING}/${ROUTES.SELECT_GRADE}`);

  return (
    <Stack spacing={2.5}>
      <Card>
        <Stack spacing={1}>
          <BackButton navigate={backToSelectGrade} text='Назад' />
          <Capybara />
        </Stack>
      </Card>
      <Card>
        <Typography>Прости, эти программы пока не готовы</Typography>
      </Card>
      <Button variant='contained' color='secondary' onClick={backToSelectGrade} subject={subject}>
        Вернуться к выбору класса
      </Button>
    </Stack>
  );
};

export default NoCourseAvailable;
