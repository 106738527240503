/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/beginner_2_lvl_4_emb.gltf
*/

/*
eslint-disable react/no-unknown-property
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import startAnimation from '../helpers/startAnimation';

const BeginnerModel = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/beginner_2_lvl_4_emb.gltf');
  const { actions, names } = useAnimations(animations, group);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    startAnimation(actions, names);
  }, [actions, names]);

  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <group name='ARMA_beginner_2_lvl_4'>
          <primitive object={nodes.pivot} />
          <group name='MESH_beginner_2_lvl_4'>
            <skinnedMesh
              castShadow
              name='Plane005'
              geometry={nodes.Plane005.geometry}
              material={materials.Texture_capibara}
              skeleton={nodes.Plane005.skeleton}
            />
            <skinnedMesh
              castShadow
              name='Plane005_1'
              geometry={nodes.Plane005_1.geometry}
              material={materials.Texture_beginner_2_lvl_4}
              skeleton={nodes.Plane005_1.skeleton}
            />
          </group>
        </group>
      </group>
    </group>
  );
};

export default BeginnerModel;

useGLTF.preload('/beginner_2_lvl_4_emb.gltf');
