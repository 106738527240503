import { useNavigate } from 'react-router-dom';
import { Card, Stack, Typography } from '@mui/material';
import Capybara from 'pages/Onboarding/components/Capybara';
import InfoBanner from 'components/dashboard/InfoBanner';
import ROUTES from 'constants/routes';
import { useSetUserCommentMutation } from '@generated/graphql';
import React, { useContext } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { ArrowForward } from '@mui/icons-material';

enum Role {
  Student,
  Parent,
}

const ROLE = {
  [Role.Student]: 'Ученик',
  [Role.Parent]: 'Родитель',
};

const SelectUserRole = () => {
  const navigate = useNavigate();
  const { addToast } = useContext(ToastContext);
  const [setUserComment] = useSetUserCommentMutation();

  const handleRoleSelect = (role: Role) => {
    setUserComment({
      variables: { comment: ROLE[role] },
    })
      .then(() => navigate(ROUTES.SELECT_GRADE))
      .catch((res) => {
        if (res.message === 'Comment already exists') {
          navigate(ROUTES.SELECT_GRADE);
        } else {
          addToast({ type: ToastTypeEnum.ERROR });
        }
      });
  };

  return (
    <Card>
      <Stack spacing={2.5}>
        <Capybara />
        <Typography variant='h2'>Кто ты?</Typography>
        {[Role.Parent, Role.Student].map((role) => (
          <InfoBanner icon={<ArrowForward />} key={role} text={ROLE[role]} onClick={() => handleRoleSelect(role)} />
        ))}
      </Stack>
    </Card>
  );
};

export default SelectUserRole;
