import { Box, Stack, Tooltip, Typography } from '@mui/material';
import React, { FC, MouseEvent } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import colors from 'theme/colors';
import { IOutletContext } from 'types/common';
import CourseColorButton from 'components/buttons/CourseColorButton';
import ROUTES from 'constants/routes';
import { CourseQuery } from '@generated/graphql';
import { checkIsUserCanBuyBlock } from 'hooks/useBlockData';

type BlockCardProps = NonNullable<CourseQuery['course']['blocks']>[number] & {
  totalTaskCount: number | undefined;
  doneTaskCount: number | undefined;
  backgroundImage: string | undefined;
  progress: number;
};

const BlockCard: FC<BlockCardProps> = ({ totalTaskCount, doneTaskCount, title, backgroundImage, progress, id: blockId, isAvailable, unavailableReason }) => {
  const navigate = useNavigate();
  const { subject } = useOutletContext<IOutletContext>();

  const params = useParams<{ courseId: string }>();
  const courseId = params.courseId as string;

  const generateBackground = () => {
    const color = colors[subject].cover;
    return `url(${backgroundImage}), ${color}})`;
  };

  const background = generateBackground();

  const isUserCanBuyBlock = checkIsUserCanBuyBlock(unavailableReason);

  const handleBuyBlock = (e?: MouseEvent) => {
    if (e) e.stopPropagation();
    navigate(`/${ROUTES.PAYMENT}/${courseId}/${blockId}`);
  };

  const handleBlockClick = () => navigate(blockId);

  return (
    <Box component='div' onClick={handleBlockClick} className='slide-element'>
      <Tooltip title={isAvailable ? '' : 'Предпросмотр блока'}>
        <Stack
          justifyContent='space-between'
          sx={{
            borderRadius: 4,
            bgcolor: 'baseShift.400',
            width: 190,
            padding: 2,
            height: 260,
            cursor: 'pointer',
          }}
        >
          <Typography variant='h6'>{title}</Typography>
          <Stack spacing={1} alignItems='flex-end'>
            {isAvailable && (
              <Box
                component='div'
                sx={({ palette: { mode, base } }) => ({
                  borderRadius: 5,
                  backgroundColor: base[mode === 'light' ? 400 : 700],
                  textAlign: 'center',
                  height: 'fit-content',
                  paddingX: 0.5,
                  minWidth: 45,
                })}
              >
                <Typography variant='text7' color='base.100'>
                  {`${Math.round(progress * 100)}%`}
                </Typography>
              </Box>
            )}
            {isUserCanBuyBlock && (
              <CourseColorButton fullWidth variant='contained' subject={subject} size='small' onClick={handleBuyBlock}>
                Купить
              </CourseColorButton>
            )}
            <Stack
              sx={{
                backgroundPosition: 'bottom',
                borderRadius: 3,
                padding: 1,
                opacity: !isAvailable ? 0.4 : 1,
                width: '100%',
                height: 112,
                backgroundSize: '107%',
                backgroundRepeat: 'no-repeat',
                backgroundImage: background,
              }}
            >
              <Box
                component='div'
                sx={({ palette: { base } }) => ({
                  borderRadius: 5,
                  backgroundColor: base[750],
                  textAlign: 'center',
                  height: 'fit-content',
                  paddingX: 0.5,
                  width: 45,
                })}
              >
                <Typography variant='text7' color='base.100'>
                  {`${doneTaskCount || 0}/${totalTaskCount || 0}`}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Tooltip>
    </Box>
  );
};

export default BlockCard;
