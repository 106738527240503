import { createSvgIcon } from '@mui/material';

const CalendarIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25' fill='none'>
    <path
      d='M19.2 4.05158H18.3V2.2334H16.5V4.05158H7.5V2.2334H5.7V4.05158H4.8C3.81 4.05158
      3 4.86976 3 5.86976V20.4152C3 21.4152 3.81 22.2334 4.8 22.2334H19.2C20.19
      22.2334 21 21.4152 21 20.4152V5.86976C21 4.86976 20.19 4.05158 19.2 4.05158ZM19.2
       20.4152H4.8V10.4152H19.2V20.4152ZM19.2 8.59703H4.8V5.86976H19.2V8.59703Z'
      fill='currentColor'
    />
  </svg>,
  'Calendar',
);

export default CalendarIcon;
