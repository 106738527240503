import { Palette } from '@mui/material';

const getColor = (score: number | null | undefined, maxScore: number, palette: Palette) => {
  if (score === null) return palette.baseShift[400];
  const successPercent = ((score ?? 0) / maxScore) * 100;
  if (successPercent < 34) return palette.assessment.dark.fault;
  if (successPercent > 34 && successPercent < 67) return palette.assessment.dark.good;
  return palette.assessment.dark.excellent;
};

export default getColor;
