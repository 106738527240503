import CapybaraSmartImg from 'assets/images/capybara-smart.png';
import AtomsBottomImg from 'assets/images/chemistry/atoms-bottom.svg';
import AtomsTopImg from 'assets/images/chemistry/atoms-top.svg';
import { Card, Typography } from '@mui/material';
import { useOutletContext } from 'react-router-dom';
import { IOutletContext } from 'types/common';
import { CourseSubject } from '@generated/graphql';
import MicrobImg from 'assets/images/biology/microb.png';

const CheerCard = () => {
  const { subject } = useOutletContext<IOutletContext>();
  const isBiology = subject === CourseSubject.Biology;

  const generateBackground = () => {
    if (isBiology) {
      return {
        backgroundSize: 'contain, 55px',
        backgroundPosition: 'right center, 150px 116%',
        backgroundImage: `url(${CapybaraSmartImg}), url(${MicrobImg})`,
        backgroundBlendMode: 'normal, luminosity',
        position: 'relative',
        '&::after': {
          content: '""',
          display: 'block',
          backgroundSize: '76px',
          backgroundPosition: '10px 7px',
          backgroundImage: `url(${MicrobImg})`,
          mixBlendMode: 'luminosity',
          backgroundRepeat: 'no-repeat',
          height: 100,
          width: 100,
          transform: 'rotate(-165deg)',
        },
        '&::before': {
          content: '""',
          display: 'block',
          backgroundSize: '46px, 31px',
          backgroundPosition: '95px 122px,132px 34px',
          backgroundImage: `url(${MicrobImg}), url(${MicrobImg})`,
          backgroundRepeat: 'no-repeat',
          height: 150,
          width: 150,
          right: 0,
          top: 0,
          position: 'absolute',
          transform: 'rotate(-90deg)',
        },
      };
    }
    return {
      backgroundSize: '144px,204px 97px,186px',
      backgroundPosition: 'right center,left bottom,right top',
      backgroundImage: `url(${CapybaraSmartImg}), url(${AtomsBottomImg}), url(${AtomsTopImg})`,
    };
  };

  const background = generateBackground();

  return (
    <Card
      sx={({ palette }) => ({
        backgroundColor: palette[subject].main,
        height: 150,
        backgroundRepeat: 'no-repeat',
        ...background,
      })}
    >
      <Typography variant='h2' sx={{ color: 'base.100', width: '50%' }}>
        У тебя все получится!
      </Typography>
    </Card>
  );
};

export default CheerCard;
