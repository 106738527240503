import { useGetTrialMutation, useOnboardingCourseCardsQuery } from '@generated/graphql';
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Card, Stack, Typography } from '@mui/material';
import { BackButton } from 'components/index';
import ROUTES from 'constants/routes';
import CircularLoading from 'components/CircularLoading';
import React, { useContext } from 'react';
import { ToastContext, ToastTypeEnum } from 'context/toastContext';
import { OnboardingOutletProps } from 'pages/Onboarding/OnboardingOutlet';
import CourseColorButton from 'components/buttons/CourseColorButton';
import Index from './components/CourseCard';
import NoCourseAvailable from './components/NoCourseAvailable';

const SelectCourse = () => {
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  const { grade: currentGrade } = useParams<{ grade: string }>();

  const { selectedCoursesByGrade } = useOutletContext<OnboardingOutletProps>();
  const selectedCourses = currentGrade ? selectedCoursesByGrade[currentGrade] || [] : [];

  const { data, loading, error } = useOnboardingCourseCardsQuery({
    variables: {
      grade: currentGrade ? parseInt(currentGrade, 10) : undefined,
    },
    skip: !currentGrade,
  });

  const courseCards = data?.onboardingCourseCards;

  const navigateToSelectGrade = () => navigate(`/${ROUTES.ONBOARDING}/${ROUTES.SELECT_GRADE}`);

  const [getTrial, { loading: isSavingCourses }] = useGetTrialMutation();

  const handleSaveSelectedCourses = () => {
    if (selectedCourses.length > 0) {
      getTrial({
        variables: {
          coursesIds: selectedCourses,
        },
      }).then((res) => {
        if (res.data?.getTrial) {
          navigate(`/${generatePath(ROUTES.COURSE, { courseId: selectedCourses[0] })}`);
        } else {
          addToast({ type: ToastTypeEnum.ERROR });
        }
      });
    }
  };

  if (loading) return <CircularLoading />;
  if (!courseCards?.length || error) return <NoCourseAvailable />;

  return (
    <>
      <Stack spacing={2.5} sx={{ mb: 2.5 }}>
        <Card component={Stack} spacing={2.5}>
          <BackButton navigate={navigateToSelectGrade} text='Назад' />
          <Typography variant='h2'>Выбери курсы:</Typography>
          {courseCards?.map((card) => <Index key={card.id} card={card} />)}
        </Card>
      </Stack>
      {!!selectedCourses?.length && (
        <CourseColorButton
          sx={{
            position: 'sticky',
            bottom: 24,
          }}
          loading={isSavingCourses}
          variant='contained'
          color='secondary'
          subject='defaultColor'
          onClick={handleSaveSelectedCourses}
        >
          Завершить выбор
        </CourseColorButton>
      )}
    </>
  );
};

export default SelectCourse;
