import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { Button, Stack, Typography } from '@mui/material';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import { formatISODate } from 'helpers/formaters';
import { ArrowForward } from '@mui/icons-material';
import { ExercisePageParams } from 'pages/Lesson/ExercisePage';
import { Text } from 'components/ElementsGenerator';

export interface IExerciseLinkProps {
  description?: string | null;
}

const ExerciseLink = ({ description }: IExerciseLinkProps) => {
  const params = useParams<ExercisePageParams>();
  const navigate = useNavigate();
  const { subject, homework, lesson } = useOutletContext<IExerciseOutletContext>();

  const { dateTo } = lesson;
  const isHomeworkDone = !!homework?.completeness?.isDone;
  const isHomeworkAvailable = !!homework?.isAvailable;
  const navigateToHomeWork = () => navigate(generatePath(`/${ROUTES.EXERCISE}${isHomeworkDone ? '/info' : ''}`, { ...params, exerciseId: homework.id }));

  if (!homework || !isHomeworkAvailable) return null;

  return (
    <div>
      <Stack spacing={1}>
        <Typography variant='h5'>{homework.title}</Typography>
        {description && <Text content={description || ''} />}
      </Stack>
      {dateTo && <Typography sx={{ mt: 2, color: 'base.400' }} variant='text7'>{`Выполни задание до ${formatISODate(dateTo)}`}</Typography>}
      <Button sx={{ mt: 2 }} variant='contained' color='primary' endIcon={<ArrowForward />} fullWidth subject={subject} onClick={navigateToHomeWork}>
        Перейти к выполнению
      </Button>
    </div>
  );
};

export default ExerciseLink;
