/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.13 public/legend_lvl_19_emb.gltf
*/

/*
eslint-disable react/no-unknown-property
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import startAnimation from '../helpers/startAnimation';

const LegendLvl19 = (props) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/legend_lvl_19_emb.gltf');
  const { actions, names } = useAnimations(animations, group);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    startAnimation(actions, names);
  }, [actions, names]);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name='Scene'>
        <group name='ARMA_legend_lvl_19'>
          <primitive object={nodes.pivot} />
          <skinnedMesh
            castShadow
            name='MESH_legend_lvl_19'
            geometry={nodes.MESH_legend_lvl_19.geometry}
            material={materials.Texture_capibara}
            skeleton={nodes.MESH_legend_lvl_19.skeleton}
          />
        </group>
        <group name='ARMA_star'>
          <primitive object={nodes.Bone} />
        </group>
        <group name='ARMA_star001'>
          <primitive object={nodes.Bone_1} />
        </group>
        <group name='ARMA_star002'>
          <primitive object={nodes.Bone_2} />
        </group>
        <group name='ARMA_star003'>
          <primitive object={nodes.Bone_3} />
        </group>
        <group name='ARMA_star004'>
          <primitive object={nodes.Bone_4} />
        </group>
        <group name='ARMA_extra_stars'>
          <primitive object={nodes.root} />
          <skinnedMesh
            name='MESH_extra_stars'
            geometry={nodes.MESH_extra_stars.geometry}
            material={materials.Texture_legend_lvl_19}
            skeleton={nodes.MESH_extra_stars.skeleton}
          />
        </group>
        <group name='ARMA_extra_stars001'>
          <primitive object={nodes.root_1} />
          <skinnedMesh
            name='MESH_extra_stars001'
            geometry={nodes.MESH_extra_stars001.geometry}
            material={materials.Texture_legend_lvl_19}
            skeleton={nodes.MESH_extra_stars001.skeleton}
          />
        </group>
        <group name='ARMA_extra_stars002'>
          <primitive object={nodes.root_2} />
          <skinnedMesh
            name='MESH_extra_stars002'
            geometry={nodes.MESH_extra_stars002.geometry}
            material={materials.Texture_legend_lvl_19}
            skeleton={nodes.MESH_extra_stars002.skeleton}
          />
        </group>
        <group name='ARMA_extra_stars003'>
          <primitive object={nodes.root_3} />
          <skinnedMesh
            name='MESH_extra_stars003'
            geometry={nodes.MESH_extra_stars003.geometry}
            material={materials.Texture_legend_lvl_19}
            skeleton={nodes.MESH_extra_stars003.skeleton}
          />
        </group>
        <group name='ARMA_extra_stars004'>
          <primitive object={nodes.root_4} />
          <skinnedMesh
            name='MESH_extra_stars004'
            geometry={nodes.MESH_extra_stars004.geometry}
            material={materials.Texture_legend_lvl_19}
            skeleton={nodes.MESH_extra_stars004.skeleton}
          />
        </group>
      </group>
    </group>
  );
};

export default LegendLvl19;

useGLTF.preload('/legend_lvl_19_emb.gltf');
