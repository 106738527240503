import React, { useEffect, useRef, useState } from 'react';
import KinescopePlayer from '@kinescope/react-kinescope-player';
import { Box, Link, Stack } from '@mui/material';
import { useCreateSingleProgressMutation } from '@generated/graphql';
import useTelegram from 'hooks/useTelegram';
import { useOutletContext, useParams } from 'react-router-dom';
import { IExerciseOutletContext } from 'pages/Lesson/Lecture/Lecture';
import { LessonPageParams } from 'pages/Lesson';

export type VideoProps = {
  kinescopeId: string;
  id: string;
};

interface ProgressData {
  currentTime: number;
  duration: number;
  percent: number;
  ended: boolean;
}

const Video = ({ kinescopeId, id }: VideoProps) => {
  const params = useParams<LessonPageParams>();
  const { subject, refetchLesson, lesson } = useOutletContext<IExerciseOutletContext>();
  const videoRef = useRef<any>(null);

  const [isExerciseDone, setIsExerciseDone] = useState<boolean | null | undefined>(
    lesson?.exercises?.find((exercise) => exercise.id === params.exerciseId)?.completeness?.isDone,
  );

  const [createProgress, { loading: createProgressLoading }] = useCreateSingleProgressMutation();

  // { currentTime: number; duration: number; percent: number; ended: boolean }
  const onCreateProgress = (data: any) => {
    const progressData = data as ProgressData;
    if (!isExerciseDone && !createProgressLoading && progressData.percent >= 1) {
      createProgress({
        variables: {
          elementId: id,
        },
      })
        .then((res) => {
          setIsExerciseDone(res.data?.createSingleProgress?.isCorrect); // чтобы точно остановить повторную запись прогресса
          setTimeout(() => refetchLesson?.(), 0); // небольшая задержка, чтобы все данные на сервере обновились
        })
        .catch(console.error);
    }
  };

  const { tg } = useTelegram();

  const isMobilePlatform = ['android', 'ios'].includes(tg.platform);

  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  useEffect(() => {
    const handleWindowResize = () => setIsLandscape(window.innerWidth > window.innerHeight);

    window.addEventListener('resize', handleWindowResize);
    handleWindowResize(); // Убедиться, что состояние обновляется при первом рендере

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <Stack spacing={1}>
      <Box
        component='div'
        sx={{
          width: isMobilePlatform && isLandscape ? 'auto' : '100%',
          position: 'relative',
          paddingTop: isMobilePlatform && isLandscape ? '262px' : '56.25%',
          '& iframe': { position: 'absolute', top: 0, left: 0 },
        }}
      >
        <KinescopePlayer ref={videoRef} videoId={kinescopeId} onTimeUpdate={onCreateProgress} />
      </Box>
      {tg.platform !== 'unknown' && (
        <Link target='_blank' href={`https://kinescope.io/embed/${kinescopeId}`} sx={(theme) => ({ color: theme.palette[subject].main })}>
          Открыть в полноэкранном режиме
        </Link>
      )}
    </Stack>
  );
};

export default Video;
