import React, { createContext, ReactNode, useMemo, useState } from 'react';
import { CourseSubjectColor } from 'types/common';
import { CourseSubject } from '@generated/graphql';

interface ISubjectContextProps {
  subject: CourseSubjectColor;
  setSubject: React.Dispatch<React.SetStateAction<CourseSubject>>;
}

export const SubjectContext = createContext<ISubjectContextProps>({
  subject: CourseSubject.Biology,
  setSubject: () => {},
});

export const SubjectProvider = ({ children }: { children: ReactNode }) => {
  const [subject, setSubject] = useState(CourseSubject.Biology);

  const providerValue = useMemo(() => ({ subject, setSubject }), [subject]);

  return <SubjectContext.Provider value={providerValue}>{children}</SubjectContext.Provider>;
};
