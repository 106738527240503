import { styled } from '@mui/material/styles';
import useThemeMode from 'hooks/useThemeMode';
import { LoadingButton } from '@mui/lab';

const CourseColorButton = styled(LoadingButton)(({ subject, theme }) => {
  const { isLightTheme } = useThemeMode();
  return {
    backgroundColor: subject ? theme.palette[subject].main : theme.palette.baseShift[500],
    color: theme.palette.base[100],
    '&:hover': {
      backgroundColor: isLightTheme ? theme.palette.base[100] : theme.palette.base[600],
      color: theme.palette.baseShift.text,
    },
  };
});

export default CourseColorButton;
