import React, { createContext, ReactNode, useMemo, useState } from 'react';
import { Modal } from 'components';
import { CourseSubject } from '@generated/graphql';
import { ModalProps } from 'components/Modal';
import PlaceholderModal from 'components/modals/PlaceholderModal';

interface ModalContextType {
  handleOpen: (modalProps: Omit<ModalProps, 'open' | 'onClose'> & { modalType: ModalType }) => void;
}

export const ModalContext = createContext<ModalContextType>({
  handleOpen: () => {},
});

export enum ModalType {
  Default = 'Default',
  Placeholder = 'Placeholder',
}

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<Omit<ModalProps, 'open' | 'onClose'> & { modalType: ModalType }>({
    actionButton: undefined,
    content: undefined,
    img: undefined,
    title: '',
    bodyText: '',
    loading: false,
    mainButton: { text: '', action: () => {} },
    subject: CourseSubject.Biology,
    modalType: ModalType.Default,
  });

  const handleOpen = ({ title, bodyText, mainButton, ...rest }: Omit<ModalProps, 'open' | 'onClose'> & { modalType: ModalType }) => {
    setContent({ title, bodyText, mainButton, ...rest });
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const providerValue = useMemo(() => ({ handleOpen }), [handleOpen]);

  return (
    <ModalContext.Provider value={providerValue}>
      {content.modalType === ModalType.Default ? (
        <Modal open={open} onClose={handleClose} {...{ ...content }} />
      ) : (
        <PlaceholderModal
          open={open}
          onClose={handleClose}
          subject={content.subject}
          closeButtonText={content.mainButton?.text!}
          mainButtonText={content.actionButton?.text!}
          headerText={content.bodyText!}
        />
      )}
      {children}
    </ModalContext.Provider>
  );
};
